import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../../core/navbar.service';
@Component({
  selector: 'home-coming-soon',
  templateUrl: './home-coming-soon.component.html',
  styleUrls: ['./home-coming-soon.component.scss']
})
export class HomeComingSoonComponent implements OnInit {

  constructor(public nav: NavbarService,) { }

  ngOnInit() {
    this.nav.show();
  }

}
