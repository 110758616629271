

<!-- <div class="full-bg-img" style="background-image: url(../../../assets/images/cloudsSmall.jpg);background-size: cover;"> -->

            <top-nav></top-nav>
            <router-outlet></router-outlet>
            <footer-nav></footer-nav>

<!-- </div> -->


<!-- Old message service no longer used -->
<!-- <notification-message></notification-message> -->

<!--Coming Soon-->
<!--<top-nav-coming-soon></top-nav-coming-soon>
<home-coming-soon></home-coming-soon>-->
