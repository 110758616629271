import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NotifyService } from '../../core/notify.service';
import { ToastService } from 'ng-uikit-pro-standard'; 


@Component({
  selector: 'notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationMessageComponent implements OnInit {

  constructor(public notify: NotifyService, private toast: ToastService) { }

  ngOnInit() { }

}
