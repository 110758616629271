import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Subscription } from 'rxjs';
import * as firebase from 'firebase/app';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser} from '@angular/common';
//firebase.initializeApp(environment.firebaseConfig)


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  userSubscribe: Subscription;
  email: string;
  password: string;
  error: any
  screenTrace : firebase.performance.Trace;
  isBrowser: boolean;
  perf:any;

  constructor( @Inject(PLATFORM_ID) platformId: Object, public auth: AuthService, ) 
  { this.isBrowser = isPlatformBrowser(platformId); }

  onSubmit(formData: any) {
    this.auth.emailLogin(this.email, this.password)
      .then(() => {

      })
      .catch(error => { console.log(error); this.error = error});
  }

  ngOnInit() {

    this.userSubscribe = this.auth.currentUser.subscribe((user) => {
      if (user) {
        console.log("Signed In")
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }

}
