

<!--Navbar-->

<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark mdb-color darken-3">
    <logo>
      <a class="logo navbar-brand titlefont" routerLink="/" ripple-radius>
          <strong>Cyfer<a class="hackerlinxGreen">Linx</a></strong></a>
    </logo>
      <links>
          <ul class="navbar-nav mr-auto">
              <li class="nav-item" ripple-radius>
                  <a class="nav-link" routerLink="/"> CyferLinx <span class="sr-only"></span></a>
              </li>
              
          </ul>

      
      </links>
  </mdb-navbar>
  
  
  
  
  