
import {take, switchMap} from 'rxjs/operators';
import { Injectable} from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestoreModule} from '@angular/fire/firestore';
import { Observable , BehaviorSubject,  Subscription ,  of } from 'rxjs';
import { User, SharedUser, RecruiterJob } from './user';
//export const firebaseConfig = environment.firebaseConfig;
import { environment } from '../../environments/environment';
import { ToastService } from 'ng-uikit-pro-standard'; 

@Injectable()
export class AuthService {

  userRef: AngularFireObject<any>; 
  user: Observable<User> = of(null);
  role: Observable<any> = of(null)
  currentUser: BehaviorSubject<User> = new BehaviorSubject(null);
  publicUser: BehaviorSubject<User> = new BehaviorSubject(null);
  roles: BehaviorSubject<any> = new BehaviorSubject(null);
  tempUser: User = null;
  sharedUser: SharedUser = null;
  userSubscribe: Subscription;
  blastfurnaceSub: Subscription;
  topcoderSub: Subscription;
  private userLoaded: boolean = false;
  private roleLoaded: boolean = false;
  userObs: Observable<User> = of(null);
  roleObs: Observable<User> = of(null);
  publicUserObs: Observable<any> = of(null);
  FirstName : string;
  LastName: string;
  companyCollection: AngularFirestoreCollection<RecruiterJob>;
  //secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary")

  constructor( private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private toast: ToastService,) {

    //// Get auth data, then get firestore user document || null
    this.user.pipe(switchMap(() => 
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          let verified = this.afAuth.auth.currentUser.emailVerified || (this.afAuth.auth.currentUser.providerData[0].providerId=="github.com")
          if(verified == true){
            //console.log("User Verified")
            this.afs.doc<User>(`users/${user.uid}`).update({ verified: true })
          }
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          //console.log("returning null")
          return of(null)
        }

      }))
    )).subscribe(this.currentUser)
    //console.log("Auth service init");

      this.role.pipe(switchMap(() =>
      this.role = this.afAuth.authState.pipe(
        switchMap(user => {
          if(user){
            return this.afs.doc(`roles/${user.uid}`).valueChanges()
          } else {
            return of(null)
          }
        }))
      )).subscribe(this.roles)
  }

  getUserByIDString(userIDString): BehaviorSubject<SharedUser>{
    const path = `users/${userIDString}`; // Endpoint on firebase
    const userRef: AngularFirestoreDocument<any> = this.afs.doc<SharedUser>(path);
    const sharedUser: BehaviorSubject<SharedUser> = new BehaviorSubject(null);
    this.afs.doc<SharedUser>(path).valueChanges().subscribe(sharedUser);
    return sharedUser;
  }
  emailLogin(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        if(user){
          this.user = this.afs.doc<User>(`users/${this.afAuth.auth.currentUser.uid}`).valueChanges();
        }
      })
    .catch(error => this.handleError(error) );
  }
  private handleError(error) {
    this.toast.error(error.message)
  }
}