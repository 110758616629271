import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'top-nav-coming-soon',
  templateUrl: './top-nav-coming-soon.component.html',
  styleUrls: ['./top-nav-coming-soon.component.scss']
})
export class TopNavComingSoonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
