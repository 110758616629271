<div class="blast-furnace">
    <div class="full-bg flex"  style="background-image: url(../../../assets/images/blastfurnace.jpg); background-size: cover; ">
        
        <blast-furnace-nav [enableLogo]="true"></blast-furnace-nav>   
    
    </div>
    <div class="intro-info-content text-center">
      <h6 class="heading" data-wow-delay="0.3s">Competition Event History</h6>
    </div>

    <div class="containerLeaderboard">
      <div *ngFor="let event of Events; let i = index">
        <div *ngIf="event.results">
            <div class="row justify-content-center">
                <div class="container">
                  <div class="card" >
                    <table class="table" >
                        <thead class="tableHeader">   
                          <tr>
                              <th class="bfMonth"  style="text-align: left">{{event.date}} , {{event.time}}</th>
                              <th class="bfMonth" style="text-align: right">Participants: {{event.users.length}}</th>
                          </tr>
                        </thead>
                    </table>
                         

                  <table class="table" >
                      <thead class="tableHeader"> 
                      <tr>
                        <th class="tableHeadings" style="text-align: left">NAME</th>
                        <th class="tableHeadings" style="text-align: center">LANGUAGE</th>
                        <th class="tableHeadings"style="text-align: right">SCORE</th>
                      </tr>
                      </thead>
                      <!--Table head-->
            
                      <!--Table body-->
                      <tbody>
                        <tr *ngIf="event.results[0]">
                            <td>
                                    <img src="{{event.results[0].avatar}}"><span style="white-space: no wrap;">{{event.results[0].firstName}} {{event.results[0].lastName}}</span>
                                
                            </td>
                            <td  style="text-align: center">
                              {{event.results[0].language}}
                            </td>
                            <td style="text-align: right">
                                <i  class="fa fa-trophy trophyGold"></i> {{event.results[0].score}}
                            </td>
                        </tr>
                        <tr *ngIf="event.results[1]">
                            <td>
                                <img src="{{event.results[1].avatar}}"> {{event.results[1].firstName}} {{event.results[1].lastName}}
                            </td>
                            <td  style="text-align: center">
                                {{event.results[1].language}}
                            </td>
                            <td style="text-align: right">
                                <i  class="fa fa-trophy trophySilver"></i> {{event.results[1].score}}
                            </td>
                        </tr>
                        <tr *ngIf="event.results[2]">
                            <td>
                                <img src="{{event.results[2].avatar}}"> {{event.results[2].firstName}} {{event.results[2].lastName}}
                            </td>
                            <td  style="text-align: center">
                                {{event.results[2].language}}
                            </td>
                            <td style="text-align: right">
                                <i  class="fa fa-trophy trophyBronze"></i> {{event.results[2].score}}
                            </td>
                        </tr>
    
                      </tbody>
                      
                    </table>
                  </div>
                </div>
            </div>
            <br>
        </div>
        </div>
        
  </div>
