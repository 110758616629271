<div class="container" style="background-color: white; padding-top: 3%;">
  <h1>PRIVACY POLICY</h1>
  <p>
    <b>Saphi Pty Ltd (the Company) is committed to protecting your privacy. This Privacy Policy tells you about our
     online collection of personal information and how we disclose and use that information.
    </b>
  </p>

  <p>
      By visiting and/or using this site, you agree to the terms of this policy and you consent to our collection, 
      use and disclosure of your personal information in accordance with what is set out below. This site is owned 
      by the Company and may be accessed in Australia and overseas. For data protection purposes we are the controller 
      and, unless otherwise noted, we are also the processor of data. 
  </p>

  <p>
      The collection, use and disclosure of your personal information is governed by the Privacy Act 1988 (Cth) if 
      you are in Australia or the General Data Protection Regulation (<b>GDPR</b>) if you are in Europe.
  </p>

  <h2>The type of personal information we collect</h2>
  <p>
      This Privacy Policy covers personal information you give to us or that we collect automatically when you visit 
      our website. Personal information is information that can be used to identify you. Personal information might 
      include your username and password, your name, your mailing address, email address, phone number and financial 
      information such as your credit card number and billing address.  
  </p>

  <h2>Personal information given by you to us</h2>
  <p>
      We collect the personal information you give us in a number of ways such as when you complete an application form
      or submit your resume either online or in hard copy, attend an interview (either online or in person), or 
      otherwise provide us with personal information in person when you attend our premises, online, by email, post and 
      facsimile or over the phone.  
  </p>
  <p>
      Personal information is collected so that you can more easily do business with us and includes general contact 
      and identification details. 
  </p>
  <p>
      You may always choose not to provide personal information, but if you so choose, certain products and services 
      may not be available to you. You can withdraw your consent at any time by contacting us (see contact us section 
      below).
  </p>

  <h2>Information collected from third parties</h2>
  <p>
      We try and collect personal information directly from you. However, we may collect personal information from 
      third parties. 
  </p>

  <h2>Information automatically collected </h2>
  <p>
      When you visit this site, other kinds of information may also be collected automatically as part of this site’s 
      operation. This includes the website that referred you to us, your IP address, browser type and language, and 
      access times. We may also collect navigational information, including information about the pages you view, the 
      links you click, and other actions taken in connection with the site. We may combine your visit and navigational 
      information with personal information that you provide. 
  </p>

<p>
    We use "cookies" to enable you to sign in to our services and to help personalise your online experience. A cookie 
    is a small text file that is placed on your hard drive. Cookies contain information, including personal 
    information that can later be read by a web server in the domain that issued the cookie to you. The information 
    that cookies collect includes the date and time of your visit, your registration information, and your navigational
    and purchase history. Cookies offer you many conveniences. They allow us to identify registered users when they 
    return to the site so that they can retrieve previous image search results and view their previous invoices. 
    Cookies also save you time by eliminating the need to repeatedly enter the same information.
</p>
<p>
    In some cases, our third-party service providers may use cookies on our sites. We have no access to or control over
     these cookies. This Privacy Policy covers the use of cookies by the Company only and does not cover the use of 
     cookies by third parties.
</p>
<p>
    You have the ability to accept or decline cookies. Most browsers automatically accept cookies, but you can usually 
    modify your browser setting to decline cookies. If you choose to decline cookies, you may not be able to sign in 
    or use other interactive features of our sites and services that depend on cookies.
</p>

<p>
    In addition to cookies, we may use other technologies, including single-pixel gifs (also known as web beacons) on 
    our websites and in promotional e-mail messages or newsletters. These tiny electronic images assist us in 
    determining how many users have visited certain pages or opened messages or newsletters. We do not use these images
     to collect personal information.
</p>

<h2>Use of Your Personal Information</h2>
<p>
    We use the personal information you have given us for purposes which are consistent with the reason you provided the 
    personal information, or for a directly related purpose. 
</p>
<p>
    We may also use or disclose your personal information where you have consented to the use, either expressly or by 
    implication.
</p>

<h2>Sharing of Your Personal Information</h2>
<p>
    We disclose your personal information for the purpose for which it was collected. As a result, your personal 
    information may be disclosed to third parties. We may disclose your personal information to external service 
    providers who perform services on our behalf. For example, we may hire other companies to handle the processing 
    of payments, to provide data storage, to host websites, to fulfil orders and shipments, to assist in direct 
    marketing, to conduct audits, etc. Those companies will be permitted to obtain only the personal information 
    they need to provide the service. 
</p>
<p>
    Any third parties to whom we disclose your personal information will be required to maintain the confidentiality 
    of the information and are prohibited from using it for any other purpose. We will take all reasonable steps to 
    make sure they comply with the same privacy principles that govern our collection of your personal information. 
</p>

<p>
    Some of these service providers may be overseas such as in America, Canada, England or Italy. While we will do our best to make 
    sure that overseas service providers to whom we give your personal information will comply with the same privacy 
    principles as us we cannot guarantee it. By giving us your personal information you consent to the disclosure of 
    personal information to overseas service providers who may not comply.
</p>
<p>
    We may share your personal information within our corporate group, including with our subsidiaries, affiliated 
    companies and divisions all of whom may use your information for the purposes disclosed in this Privacy Policy.
</p>
<p>
    We may disclose your personal information if required to do so by law, or in the good-faith belief that such 
    action is reasonably necessary to comply with legal process, respond to claims, or protect the rights, property 
    or safety of our company, employees, customers, or the public.
</p>
<p>
<b>For EU users:</b> Our company and some of our external service providers may not be in the country where you submitted 
your personal information. As a result, your personal information may be transferred to countries that have different
 levels of data protections laws to where you are. Where local data regulations require it, we have put in place 
 security measures for the export of personal information from its country of origin. Where local data regulations 
 require it, we have made arrangements with those receiving your personal information, that they will put security 
 measures in place and that your personal information is processed in accordance with EU data protection laws.
</p>

<h2>Marketing</h2>
<p>
    We may use and disclose your personal information, other than sensitive information, in order to send you surveys 
    or marketing communications to inform you of new products or services or other information that may be of 
    interest. Third parties with whom we have shared your personal information may send you direct marketing 
    communications.
</p>
<p>
    If you do not want to receive direct marketing communications from either us or a third party, it is easy to opt out.
     Go to your “My Account” settings as described below or follow the "unsubscribe" instructions included within each email
     communication. Alternatively, you can send us an email request at <a href="https://www.saphi.com.au/contact-us/" class="hackerLinxBlue">contact@saphi.com.au</a>. Please keep in mind that, if you 
     choose not to receive marketing communications, you will continue to receive transactional or account communications 
     (e.g. confirmation emails and account balance statements). 
</p>

<h2>How To Manage Your Personal Information</h2>
<p>
    You have, subject to certain exceptions allowed for by law, the right to ask us to provide you with all of the personal 
    information we store on you. If you would like to get access to, review or correct any of your personal information, after 
    you have registered, go to your profile and make the appropriate changes. You may also request changes by emailing us at <a href="https://www.saphi.com.au/contact-us/" class="hackerLinxBlue">contact@saphi.com.au</a>. 
    We can provide you with a copy of personal information held on our current records at no charge. However, we will not be obliged 
    to provide you with a copy or give you access to your personal information if by doing so we would reveal personal information about another person.
    If the GDPR applies to the collection, disclosure or use of your personal information, they you are (among other things) also entitled to:
</p>
<ul>
    <li>receive transparent information regarding the processing of your personal information;</li>
    <li>access your personal information, including the right to obtain free of charge a copy of the personal information undergoing processing in a 
        commonly available electronic format;</li>
    <li>rectification of incorrect personal information and completion of incomplete personal information;</li>
    <li>erasure of your personal information, including the “right to be forgotten”;</li>
    <li>restrict the processing of your personal information;</li>
    <li>your personal information being portable and accessible upon request; </li>
    <li>object to the processing of your personal information; and</li>
    <li>if you have consented to the processing of your personal information, you have the right to withdraw that consent.</li>
</ul>
<p>    
    If you wish to exercise any of the above rights our contact information is at the end of this Privacy Policy.
</p>
<p>
    If we have disclosed your personal information to a third party, in accordance with this Privacy Policy, we will take reasonable steps to
     notify the third party of the updated information.
</p>

<h2>Where your information is stored</h2>
<p>
    Personal information that we collect may be stored and processed in Australia and/or Canada, United States of America, Brazil, Finland, Belgium, 
    United Kindom, Germany, Netherlands, Taiwan (ROC), Japan, India and Singapore. We take all reasonable steps to protect personal information 
    no matter what country it is stored in. However, information collected may be retained, and may be stored, processed, accessed, and used in 
    jurisdictions whose privacy laws may be different and less protective than those of in Australia. By using our site or giving us the personal 
    information requested, you consent to any such transfer, processing and storage of personal information outside of Australia.
</p>

<h2>Security of Your Personal Information</h2>
<p>
    The security of your personal information is important to us. We follow accepted industry standards to help protect your personal information. 
    For instance, when you enter sensitive information (such as a credit card number) on our registration or order forms, we encrypt that information 
    using secure socket layer (<b>SSL</b>) technology. No method of transmission over the internet, or method of electronic storage, is 100% secure. Therefore, 
    while we strive to protect your personal information, we cannot guarantee its absolute security and any transmission of personal information is at your own risk. 
</p>
<p>
    If a password is used to protect your account and personal information, it is your responsibility to keep your password confidential and to change it if you think it 
    has been compromised.
</p>

<h2>Retention of your personal information</h2>
<p>
    We will only keep your information for as long as is necessary for the purposes set out in this Privacy Policy unless we are required to retain it for a longer period
     in order to comply with our legal obligations. 
</p>

<h2>Website customisation</h2>
<p>
    With your consent, and as set out in this Privacy Policy, we may use your personal information to build up a profile about you so that when you visit this site you don’t 
    miss out on offers or information that is relevant to you. 
</p>
<p>
    We, and our third party advertising service providers, may collect website usage information about users of our site for a number of purposes such as statistical analysis,
    sales and marketing research, tracking page usage and paths used by visitors through our site, targeting the site content, targeting our marketing campaigns and direct 
    marketing emails, targeting our Internet banner advertisements on our website and on other websites and tracking use of our Internet banner advertisements and other links 
    from our marketing partners' websites to our website. We may combine this information with other personal information we have collected about you eg how you arrived at our 
    site and through cookies previously stored on your machine, as explained above.  
</p>

<h2>Children</h2>
<p>
    We do not intend to solicit or collect personal information from anyone under the age of 18. If you are under 18, you should not use or enter information on this site, 
    including without limitation, any Interactive Areas of the site. 
</p>

<h2>Links</h2>
<p>
    We may link to websites, including those of our third-party content providers and social media sites.  Our website may contain social media features such as Facebook 
    “Like” buttons. These features may collect information about your device’s IP address, set cookies or link you to a social media website where you may post personal 
    information. The sites of our third-party providers and social media sites may have different privacy policies and practices from those disclosed here. We assume no 
    responsibility for the policies or practices of such sites and encourage you to become acquainted with them prior to use. 
</p>

<h2>Complaints</h2>
<p>
    If you have questions, complaints or concerns regarding this Privacy Policy, please contact us by writing to us at: <a href="https://www.saphi.com.au/contact-us/" class="hackerLinxBlue">contact@saphi.com.au</a>
</p>
<p>
    We treat all complaints about a breach of the privacy laws applicable to you seriously. Someone from the Company will investigate your complaint
    and respond to you within a reasonable time. if you are not satisfied with our response, you have the right, depending on the jurisdiction, to make
    a complaint to the applicable regulator. In Australia, the applicable regulator is the Office of the Australian Information Commissioner. In Europe, 
    the applicable regulator will be the local regulator in your jurisdiction in Europe.
</p>

<h2>Changes to This Privacy Policy</h2>
<p>
    We reserve the right to change the terms of this privacy policy at any time. If there are material changes we will post a notice of such changes here or on our home 
    page and publish the effective date of the change. We encourage you to review this policy whenever you visit our site. 
</p>

<h2>Contact Information</h2>
<p>
    The data controller responsible for your personal information for the purposes of the applicable EU data protection law is: Saphi Pty Ltd.
</p>
<p>
    You can contact Saphi Pty Ltd by emailing <a href="https://www.saphi.com.au/contact-us/" class="hackerLinxBlue">contact@saphi.com.au</a> or by sending us a letter at: 39A Pearson Street, Lambton, NSW, Australia, 2299
    or by phoning us at: +61419279704 during normal business hours EST.
</p>

</div>