import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'blast-furnace-nav',
  templateUrl: './blast-furnace-nav.component.html',
  styleUrls: ['./blast-furnace-nav.component.scss']
})
export class BlastFurnaceNavComponent implements OnInit {
  @Input() enableLogo: boolean;
  constructor() { }

  ngOnInit() {
  }

}
