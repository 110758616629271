import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection} from '@angular/fire/firestore';
import { BlastFurnaceEvent, User } from './user';
import { BehaviorSubject ,  Subscription ,  Observable ,  of } from 'rxjs';
import * as firebase from 'firebase/app';
import * as _ from 'lodash';

@Injectable()
export class BlastFurnaceService {
  blastfurnace: any;
  userSub: Subscription;
  Obs: Observable<User> = of(null);

  constructor(private afs: AngularFirestore) { }

  createEvent (users, timestamp) {
    const returnValue = new BehaviorSubject(false);
    //const returnValue = new BehaviorSubject('');
    //let timestamp = new Date().getTime().toString;
    const col = this.afs.doc(`blastFurnaceEvent/${timestamp}`);
    col.set({'users': users, 'timestamp': timestamp}).then(
    //col.set({'users': users, 'timestamp': new Date().getTime()}).then(
      (doc) => {
        returnValue.next(true);
        
      }
    );
    return returnValue;
    /*
        const returnValue = new BehaviorSubject('');
    let timestamp = new Date().getTime();
    const col = this.afs.collection(`blastFurnace/`);
    col.add({'users': users, 'timestamp': new Date().getTime()}).then(
      (doc) => {
        returnValue.next(doc['id']);
      }
    );
    return returnValue;
    */
  }

  addEventResults (eventID, scores) {
    const returnValue = new BehaviorSubject(false);
    let timestamp = new Date().getTime();
    this.afs.doc(`blastFurnaceEvent/${eventID}`).set({
      'results': scores
    }, { merge: true }).then(
      (doc) => {
        returnValue.next(true);
      }
    );
    return returnValue;

    /*const returnValue = new BehaviorSubject(false);
    this.afs.doc(`blastFurnace/${eventID}`).set({
      'results': scores
    }, { merge: true }).then(
      (doc) => {
        returnValue.next(true);
      }
    );
    return returnValue;*/
  }

  addUserLanguage(uid,language){
    const returnValue = new BehaviorSubject(false);
    let name = language;
    this.Obs = this.afs.doc<User>(`users/${uid}`).valueChanges();
      let lat: number,long:number
      this.Obs.subscribe(user =>{
        if(user){
          //console.log(user.location.latitude)
          if(user.location != null){
            lat = user.location.latitude
            long = user.location.longitude
          }
          else{
            lat = 0
            long = 0
          }
          const locationData = new firebase.firestore.GeoPoint(lat, long)
          this.afs.doc(`blastFurnaceUserLanguage/${user.uid}`).set({
          'bash' : 0 ,
          'c' : 0,
          'c++' : 0,
          'c#' : 0,
          'clojure' : 0,
          'dart' : 0,
          'f#' : 0,
          'java' : 0,
          'javascript' : 0,
          'go' : 0,
          'groovy' : 0,
          'haskell' : 0,
          'kotlin' : 0,
          'lua' : 0,
          'objective-c' : 0,
          'ocaml' : 0,
          'pascal' : 0,
          'perl' : 0,
          'php' : 0,
          'python2' : 0,
          'python3' : 0,
          'ruby' : 0,
          'rust' : 0,
          'scala' : 0,
          'swift' : 0,
          'vb.net' : 0,
          'total' : 0,
          'uid' : user.uid,
          'location' : locationData}).then((doc) => {returnValue.next(true)});
        }
      })
      return returnValue;
  }
  updateUserLanguage(uid,language){
    const returnValue = new BehaviorSubject(false);
    let name = language;
    //console.log(language)
    this.Obs = this.afs.doc<User>(`users/${uid}`).valueChanges();
      let lat: number,long:number
      this.Obs.subscribe(user =>{
        if(user){
          //console.log(user.location.latitude)
          if(user.location != null){
            lat = user.location.latitude
            long = user.location.longitude
          }
          else{
            lat = 0
            long = 0
          }
          const locationData = new firebase.firestore.GeoPoint(lat, long)
          //console.log(language)

          

          let dataShared = {};
          let dataPast = {};
          let languages = [];
          let languageList = []
          let shared = {}
          let i = 0;
          if(language.bash>0){
            languages[i]='bash'
            i++
          }
          if(language.c>0){
            languages[i]='c'
            i++
          }
          if(language["c++"]>0){
            languages[i]='c++'
            i++
          }
          if(language["c#"]>0){
            languages[i]='c#'
            i++
          }
          if(language.clojure>0){
            languages[i]='clojure'
            i++
          }
          if(language.dart>0){
            languages[i]='dart'
            i++
          }
          if(language["f#"]>0){
            languages[i]='f#'
            i++
          }
          if(language.java>0){
            languages[i]='java'
            i++
          }
          if(language.javascript>0){
            languages[i]='javascript'
            i++
          }
          if(language.go>0){
            languages[i]='go'
            i++
          }
          if(language.groovy>0){
            languages[i]='groovy'
            i++
          }
          if(language.haskell>0){
            languages[i]='haskell'
            i++
          }
          if(language.kotlin>0){
            languages[i]='kotlin'
            i++
          }
          if(language.lua>0){
            languages[i]='lua'
            i++
          }
          if(language["objective-c"]>0){
            languages[i]='objective-c'
            i++
          }
          if(language.ocaml>0){
            languages[i]='ocaml'
            i++
          }
          if(language.pascal>0){
            languages[i]='pascal'
            i++
          }
          if(language.perl>0){
            languages[i]='perl'
            i++
          }
          if(language.php>0){
            languages[i]='php'
            i++
          }
          if(language.python2>0){
            languages[i]='python2'
            i++
          }
          if(language.python3>0){
            languages[i]='python3'
            i++
          }
          if(language.ruby>0){
            languages[i]='ruby'
            i++
          }
          if(language.rust>0){
            languages[i]='rust'
            i++
          }
          if(language.scala>0){
            languages[i]='scala'
            i++
          }
          if(language.swift>0){
            languages[i]='swift'
            i++
          }
          if(language["vb.net"]>0){
            languages[i]='vb.net'
            i++
          }
          /*dataShared['blastfurnaceLanguages'] = languages
          languageList = _.uniq([...languageList, ...languages])
          dataShared['uid'] = user.uid
          dataShared['location'] = locationData
          this.afs.doc(`sharePast/${uid}`).valueChanges().take(1).subscribe(sharePast=>{
            if(sharePast){

            }
            else{
              console.log("No Share Past")
              dataPast['pastList'] = []
              dataPast['pastList'].push("default")
              dataPast['pastTotal'] = 0 
              this.afs.doc(`sharePast/${uid}`).set(dataPast);
            }
          })
          this.afs.doc(`share/${user.uid}`).valueChanges().subscribe(share=>{
            if(share){
              let shared = share
                  //console.log(shared)
                  if(shared['gitlabLanguages']){
                    languageList = _.uniq([...languageList, ...shared['gitlabLanguages']]) 
                  }
                  if(shared['githubLanguages']){
                    languageList = _.uniq([...languageList, ...shared['githubLanguages']])
                  }
                  if(shared['bitbucketLanguages']){
                    languageList = _.uniq([...languageList, ...shared['bitbucketLanguages']])
                  }
                  if(shared['codewarsLanguages']){
                    languageList = _.uniq([...languageList, ...shared['codewarsLanguages']])
                  }
                  console.log(languageList)
                  dataShared['languageList'] = languageList
                  dataShared['blastfurnaceLanguages'] = languages
                  this.afs.doc(`share/${user.uid}`).update(dataShared)
            }
            else{
              dataShared['languageList'] = languageList
              dataShared['blastfurnaceLanguages'] = languages
              this.afs.doc(`share/${uid}`).set(dataShared);
            }
            shared = null
          })
          //this.afs.doc(`share/${user.uid}`).update(dataShared)*/
          

          this.afs.doc(`blastFurnaceUserLanguage/${user.uid}`).set({
              'bash' : language.bash ,
              'c' : language.c,
              'c++' : language["c++"],
              'c#' : language["c#"],
              'clojure' : language.clojure,
              'dart' : language.dart,
              'f#' : language["f#"],
              'java' : language.java,
              'javascript' : language.javascript,
              'go' : language.go,
              'groovy' : language.groovy,
              'haskell' : language.haskell,
              'kotlin' : language.kotlin,
              'lua' : language.lua,
              'objective-c' : language["objective-c"],
              'ocaml' : language.ocaml,
              'pascal' : language.pascal,
              'perl' : language.perl,
              'php' : language.php,
              'python2' : language.python2,
              'python3' : language.python3,
              'ruby' : language.ruby,
              'rust' : language.rust,
              'scala' : language.scala,
              'swift' : language.swift,
              'vb.net' : language["vb.net"],
              'total' : language.total,
              'uid' : user.uid,
              'location' : locationData}).then((doc) => {returnValue.next(true)});
              
        }
      })
      return returnValue;
    
  }
  getEventsInRange(min, max) {
    const col = this.afs.collection(`blastFurnaceEvent/`, ref => ref.where('timestamp', '<', max).where('timestamp', '>', min));
    return col.valueChanges()
  }
  getTotalLeaderboard(language){
    const col = this.afs.collection(`blastFurnaceLeaderboard/${language}/Scores/`, ref => ref.orderBy('score', 'desc'));
    return col.valueChanges()
  }
  getUserLanguage(user){
    const doc = this.afs.doc(`blastFurnaceUserLanguage/${user}/`);
    return doc.valueChanges();
  }
  getUserRank(total){
    const col = this.afs.collection(`blastFurnaceLeaderboard/Total/Scores/`, ref => ref.where('score', '>=', total).orderBy('score', 'desc'))
    return col.valueChanges()
  }
  getUserRank2(language, user){
    const col = this.afs.doc(`blastFurnaceLeaderboard/${language}/Scores/${user}/`);
    return col.valueChanges()
  }
  getUserLanguageRank(language,score){
    const col = this.afs.collection(`blastFurnaceLeaderboard/${language}/Scores/`, ref => ref.where('score', '>=', score).orderBy('score', 'desc'))
    return col.valueChanges()
  }
  getUserScores (user) {
    //const col = this.afs.collection(`blastFurnace/`, ref => ref.where('language', '==' ,'C++' ));
    const col = this.afs.collection(`blastFurnace/`);
    return col.valueChanges();
  }

  getUserScores1 (user: User) {
    //console.log(user['uid']);
    const col = this.afs.collection(`blastFurnace`, ref => ref.where('uid', '==', user['uid']));
    const returnObservalble = new BehaviorSubject<Object>({});
    //console.log(col.valueChanges())
    col.valueChanges().subscribe(resultArray => {
      const languages = {};
      //console.log(resultArray)
      for (const item of resultArray) {
        if (item['language'] in languages) {
          languages[item['language']] += item['score']
        } else {
          languages[item['language']] = item['score']
        }
        
      }
      returnObservalble.next(languages);
    });
    
    return returnObservalble;
  }

  getEvent (id) {
    const doc = this.afs.doc(`blastFurnaceEvent/${id}`)
    return doc.valueChanges();
  }
  getUser (id) {
    const doc = this.afs.doc(`blastFurnaceUserLanguage/${id}`)
    return doc.valueChanges();
  }

  getUserByEmail (email) {
    const col = this.afs.collection('users', ref => ref.where('email', '>=', email).limit(1));
    return col.valueChanges()
  }
  getLeaderboard (uid,language){
    //console.log(uid)
    //console.log(language)
    //const doc = this.afs.doc(`blastFurnaceLeaderboard/${language}`).collection(`Scores`, ref => ref.where('uid', '==', uid));
    //const doc = this.afs.collection(`blastFurnaceLeaderboard/${language}/Scores/`, ref => ref.orderBy('score', 'desc'));// this works!!!
    const doc = this.afs.collection(`blastFurnaceLeaderboard/${language}/Scores/`, ref => ref.where('uid', '==', uid));
    return doc.valueChanges();
  }
  createLeaderboardUser(uid,language,score){
    const returnValue = new BehaviorSubject(false);
    this.afs.doc(`blastFurnaceLeaderboard/${language}/Scores/${uid}`).set({'score' : score , 'uid': uid}).then((doc) => {returnValue.next(true)});
    return returnValue;
  }
  createLeaderboardTotal(uid,score){
    const returnValue = new BehaviorSubject(false);
    this.afs.doc(`blastFurnaceLeaderboard/Total/Scores/${uid}`).set({'score' : score , 'uid': uid}).then((doc) => {returnValue.next(true)});
    return returnValue;
  }
  updateLeaderboardUser(uid,language,score){
    const returnValue = new BehaviorSubject(false);
    this.afs.doc(`blastFurnaceLeaderboard/${language}/Scores/${uid}`).set({'score' : score , 'uid': uid}).then((doc) => {returnValue.next(true)}); //update
    return returnValue;
  }
  updateLeaderboardTotal(uid,score){
    const returnValue = new BehaviorSubject(false);
    this.afs.doc(`blastFurnaceLeaderboard/Total/Scores/${uid}`).set({'score' : score , 'uid': uid}).then((doc) => {returnValue.next(true)}); //update
    return returnValue;
  }
  DeleteBlastFurnaceData(uid, language){
    //this.userSub = this.getUser(uid).subscribe(language => {
      //if(language){
        this.blastfurnace=null;
        this.blastfurnace = language
        //console.log(this.blastfurnace)
        if(this.blastfurnace.Bash >0){
          //console.log("Deleting Bash")
          const doc = `blastFurnaceLeaderboard/Bash/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.C >0){
          //console.log("Deleting C")
          const doc = `blastFurnaceLeaderboard/C/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace["C#"] >0){
          //console.log("Deleting C#")
          const doc = `blastFurnaceLeaderboard/C#/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace["C++"] >0){
          //console.log("Deleting C++")
          const doc = `blastFurnaceLeaderboard/C++/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Clojure >0){
          //console.log("Deleting Clojure")
          const doc = `blastFurnaceLeaderboard/Clojure/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Dart >0){
          //console.log("Deleting Dart")
          const doc = `blastFurnaceLeaderboard/Dart/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace["F#"] >0){
          //console.log("Deleting F#")
          const doc = `blastFurnaceLeaderboard/F#/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Go >0){
          //console.log("Deleting Go")
          const doc = `blastFurnaceLeaderboard/Go/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Groovy >0){
          //console.log("Deleting Groovy")
          const doc = `blastFurnaceLeaderboard/Groovy/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Haskell >0){
          //console.log("Deleting Haskell")
          const doc = `blastFurnaceLeaderboard/Haskell/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Java >0){
          //console.log("Deleting Java")
          const doc = `blastFurnaceLeaderboard/Java/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.JavaScript >0){
          //console.log("Deleting JavaScript")
          const doc = `blastFurnaceLeaderboard/JavaScript/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Kotlin >0){
          //console.log("Deleting Kotlin")
          const doc = `blastFurnaceLeaderboard/Kotlin/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Lua >0){
          //console.log("Deleting Lua")
          const doc = `blastFurnaceLeaderboard/Lua/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.OCaml >0){
          //console.log("Deleting OCaml")
          const doc = `blastFurnaceLeaderboard/OCaml/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace["Objective-C"] >0){
          //console.log("Deleting Objective-C")
          const doc = `blastFurnaceLeaderboard/Objective-C/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.PHP >0){
          //console.log("Deleting PHP")
          const doc = `blastFurnaceLeaderboard/PHP/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Pascal >0){
          //console.log("Deleting Pascal")
          const doc = `blastFurnaceLeaderboard/Pascal/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Perl >0){
          //console.log("Deleting Perl")
          const doc = `blastFurnaceLeaderboard/Perl/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Python2 >0){
          //console.log("Deleting Python2")
          const doc = `blastFurnaceLeaderboard/Python2/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Python3 >0){
          //console.log("Deleting Python3")
          const doc = `blastFurnaceLeaderboard/Python3/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Ruby >0){
          //console.log("Deleting Ruby")
          const doc = `blastFurnaceLeaderboard/Ruby/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Rust >0){
          //console.log("Deleting C")
          const doc = `blastFurnaceLeaderboard/Rust/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Scala >0){
          //console.log("Deleting Scala")
          const doc = `blastFurnaceLeaderboard/Scala/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace.Swift >0){
          //console.log("Deleting Swift")
          const doc = `blastFurnaceLeaderboard/Swift/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        if(this.blastfurnace["VB.NET"] >0){
          //console.log("Deleting VB.NET")
          const doc = `blastFurnaceLeaderboard/VB.NET/Scores/${uid}`
          this.afs.doc(doc).delete().then(() => {})
        }
        //this.userSub.unsubscribe()
      //}
      
    //})
    //console.log("Deleting Total")
    const doc2 = `blastFurnaceLeaderboard/Total/Scores/${uid}`
    this.afs.doc(doc2).delete().then(() => {})

    const doc = `blastFurnaceUserLanguage/${uid}`
    this.afs.doc(doc).delete().then(() => {
      //this.userSub.unsubscribe()
    })


    //console.log("BlastFurnace Deleted")
    
  }

 
  

}
