<div class="blast-furnace">

    <!-- Navbar -->
    <div class="full-bg flex"  style="background-image: url(../../assets/images/blastfurnace.jpg); background-size: cover; ">
        <blast-furnace-nav [enableLogo]="true"></blast-furnace-nav>   
    </div>

    <!-- Heading -->
    <div class="intro-info-content text-center">
      <h6 class="heading" data-wow-delay="0.3s">LESSONS</h6>
    </div>
    
    <!-- Body -->
    <div class="containerLessons">
        <div class="row justify-content-end">

        <!-- Lessons -->
        <div class="col col-md-8">
            <div class="container">
                <div *ngFor="let lesson of lessonsArray; let i = index">
                    <div *ngIf="lesson.title">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <!-- Lesson Youtube iFrame-->
                                    <div class="col-4">
                                        <div *ngIf="containsYoutube(lesson.videoLink); else elseBlock">
                                            <div class="embed-responsive embed-responsive-16by9">
                                                <iframe class="embed-responsive-item e2e-trusted-url"  [src]='lesson.videoLink' frameborder="0" 
                                                allow="autoplay; encrypted-media" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <ng-template #elseBlock>
                                            <img class="img-fluid" [src]="lesson.videoLink">
                                        </ng-template>
                                    </div>
                                    
                                    <!-- Lesson Details -->
                                    <div class="col-8 text-left align-middle">
                                        <div class="row">
                                        <div class="col-8 text-left">
                                            <div class=" bfMonth align-middle"> {{lesson.title}} </div>
                                            </div> 
                                            <div class="col-4 text-right">
                                                <div class="align-middle bfMonthDesc "> {{lesson.date}} </div>
                                            </div>
                                        </div>
                                        <div class="bfMonthDesc align-middle mb-2"> {{lesson.description}} </div>
                                        <ul>
                                            <div *ngFor="let resource of lesson.resources; let i = index">
                                                <li><a href="{{resource.link}}" target="_blank"> {{resource.name}} </a> </li>                                        </div>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>

        <!-- Sponsors -->
        <div class="col-12 col-md-2" >
            <h6 class="InvolvedDesc" style="text-align: center; padding-top:3%">SPONSORED BY</h6>
            <div *ngIf="bfData">
                <div *ngFor="let bfimage of bfData.supporterImageUrl; let k = index">
                    <center><img class="image1" [src]="bfData.supporterImageUrl[k]"></center>
                </div>
            </div>
        </div>
    </div>


    <!-- Blast Furnace Logo -->
    <div class="intro-info-content text-center">
        <img class="image" src="../../../assets/images/image.png">
    </div>

</div>
