
  <div class="blast-furnace">
  <div  class="img-fluid" style="background-image: url(../../assets/images/blastfurnace.jpg); background-size: cover;">
      <div class="full-bg-img">
            <blast-furnace-nav [enableLogo]="true"></blast-furnace-nav> 
          <div class="container flex-center">
              <!--<div class="row pt-5 mt-3">-->
                  <div class="col-md-12 mb-3">
                      <div class="intro-info-content text-center">
                          <img class="image13" src="../../../assets/images/flame-logo.svg">
                          <h6 class="h5Yellow" data-wow-delay="0.3s">CODE | COMPETE | COLLABORATE</h6>
                          <h6 class="offWhite" data-wow-delay="0.3s"><p>The Blast Furnace is a monthly meetup, competition & education session</p>
                            <p>aimed at all levels of programmers.</p></h6>
                      </div>
                      <br>
                       
                  </div>
             <!-- </div>-->
          </div>
      </div>
  </div>

  <div class="row " style="padding-top:2%">
        <div class="container flex-center">
            <div class="intro-info-content text-center">
                <h6 class="NextEvent" data-wow-delay="0.3s">Next Event</h6>
                <h6 class="NextEventBlack" data-wow-delay="0.3s">Our events are held monthly, check here for the next events time & schedule.</h6>
            </div>
        </div>
  </div>
  <div class="row" style="padding-top:1%">
    <div class="container">
        <div class="card large" >
            <div class="card-body">           
                <div class="row">
                    <div class="col-sm-7">
                        <div *ngIf="bfData">
                            <div class="bfMonth">BLAST FURNACE IN {{bfData.month}}</div>  
                        </div>
                        <p class="bfMonthDesc">Each Blastfurnace event is organised into three main areas. Introduction to coding for beginners, 
                        coding competitions for intemediate skill levels & project based collaborations for advanced coders.</p>
                    </div>
                    <div class="col-sm-5">
                            <div class="row justify-content-md-center">
                                <div class="col" style="text-align: center; padding-top: 0%; padding-bottom: 2.5%">
                                        <button mdbBtn type="button" color="yellow" mdbWavesEffect 
                                        onclick="window.location.href='https://www.meetup.com/Blast-Furnace/'"> 
                                        <a style="color:white"><i class="fab fa-meetup"></i> RSVP NOW</a> </button>
                                </div>
                            </div>
                        <div class="row">   
                                <div class="col-5 text-right">
                                    <div class="bfDTW">DATE</div>
                                </div>
                                <div *ngIf="bfData" class="col-7">
                                    <p class="bfMonthDesc">{{bfData.date}}</p>
                                </div>
                        </div>
                        <div class="row"> 
                                <div class="col-5 text-right">
                                    <div class="bfDTW">TIME</div>
                                </div>
                                <div *ngIf="bfData" class="col-7">
                                    <p class="bfMonthDesc">{{bfData.time}}</p>
                                </div>
                        </div>
                        <div class="row"> 
                                <div class="col-5 text-right">
                                    <div class="bfDTW">WHERE</div>
                                </div>
                                <div *ngIf="bfData" class="col-7">
                                    <p class="bfMonthDesc">{{bfData.where}}</p>     
                                </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="bfData && isBrowser" class="container">
                    <agm-map [latitude]="lat" [longitude]="long" [zoom]="bfData.mapZoom" style="height: 300px" [streetViewControl]="false" [zoomControl]="false">
                        <agm-marker [latitude]="lat" [longitude]="long"></agm-marker>
                    </agm-map>
                </div>     
            
                
                <hr>
                <div class="row " style="padding:1%">
                        
                    <div class="col-3 text-center" style="background-color: #E8C438;">
                        <img  class="" style="width: 100%" src="../../../assets/images/image11.png">
                    </div>
                    <div *ngIf="bfData" class="col-9 ">
                        <div class="bfMonth">{{bfData.lessonTitle}}</div>
                        <div class="bfMonthDesc">{{bfData.lessonDescription}}</div>

                        <button *ngIf="lessonLink" type="button" class="btn btn-outline-yellow waves-effect"(click)="DownloadFile(lessonLink)"><a style="color:black">View Lesson</a></button>
                        <div class="container" style="padding: 2%">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item e2e-trusted-url"  [src]='video' frameborder="0" 
                                allow="autoplay; encrypted-media" allowfullscreen></iframe>
                        </div>
                        <br>
                    </div>
                    </div>
                    
                </div>
                <hr>
                <div class="row" *ngIf="bfData">
                        
                        <div class="col-9 ">
                            
                            <div class="bfMonth" style="padding-bottom:0%">PRESENTER </div>
                            <i style="font-size: calc(7px + 1vw);">{{bfData.presenterName}}</i>
                            <br>
                            <a href="{{bfData.presenterWebsite}}" target="_blank"><i class="fa fa-globe"></i> {{bfData.presenterWebsite}}</a>
                            
                            
                        </div>
                        <div class="col-3 verticalcenter">
                        </div>
                    </div>
                <div class="row" *ngIf="bfData">
                    <div class="col-9 ">
                        <p class="bfMonthDesc">{{bfData.presenterDescription}}</p>
                    </div>
                    <div class="col-3 verticalcenter">
                            <img  class="" style="width: 100%"  [src]="bfData.presenterImageURL">
                    </div>
                </div>

            <!-- <div class="row">
                <div class="col-sm-6">
                    <div class="bfMonth">COMPETE</div>
                    <div class="bfMonthDesc">Each event there will be continuous coding competitions run. The coders will compete against each 
                            other and accrue points for the Blast Furnace leader board.</div>
                </div>
                <div class="col-sm-6">
                    <div class="bfMonth">MEET</div>
                    <div class="bfMonthDesc">Advanced coders can collaboratively work on their own projects together. Come in with the stuff you are
                        working on and get help from other members of the group.</div>
                </div>
            </div> -->
        </div>
    </div>
</div>
</div>
<div class="row " style="padding-top:2%">
    <div class="container flex-center">
        <div class="intro-info-content text-center">
            <h6 class="NextEvent" data-wow-delay="0.3s">HOW IT WORKS</h6>
            <h6 class="NextEventBlack" data-wow-delay="0.3s">Each Blastfurnace is carefully structured to accomodate all skill levels!</h6>
        </div>
    </div>
</div>
<!-- HOW it Works START-->
<!--Intro START-->
<div class="row" style="padding-top:1%">
    <div class="container"> 
        <div class="card" style="padding:1%">
            <div class="card-body">
                <div class="row">
                    <div class="col-8 text-left align-middle">
                        <div class=" bfMonth align-middle">INTRODUCTION </div>
                        <div class=" bfMonth align-middle"><span class="hiwHeadingTwo align-middle">Welcome to Blast Furnace</span></div>
                    </div>
                </div>

                <div class="row">
                    <div class="bfMonthDesc col"><p>Each Blast Furnace starts with an introduction to all coders and a break down of the 
                        coding languages and challenges we will be tackling. The guest presenter for the beginner section is introduced 
                        and the rules are covered for the coders doing the comps. We take a sneak peek at next month’s event before getting 
                        stuck into coding!.</p>
                    </div>
                </div>
                <div class="container" style="padding: 2%">
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe width="1280" height="720" src="https://www.youtube.com/embed/mQGJyxIDNnc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
<!--Intro END-->

<!--Code or COmpete START-->

<div class="row" style="padding-top:1%">
    <div class="container">
        <div class="card-deck">
  
            <div class="card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-8 text-left align-middle">
                            <div class=" bfMonth align-middle">CODE <span class="hiwHeadingTwo align-middle">Learn to code.</span></div>
                        </div>
                        <div class="col-sm-4 text-right">
                            <button type="button" class="btn btn-outline-yellow waves-effect float-right" (click)="DownloadFile(fundamentalsLink)"><a style="color:black;">Fundamentals</a></button>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="bfMonthDesc col"><p>This section will run through the basics of coding for using the technology of the night. Will be a Do-as-I-Do format where the participants follow the 
                            presenter after some basic introduction. This should not be too in-depth and will walk you through the “Hello World” of 
                            the technology or software we will be using for this event. This will run for 1 hour. </p>
                            </div>
                    </div>
                </div>
            </div>

<!--             <div class="card mb-3" >
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-8 text-left align-middle">
                            <div class=" bfMonth align-middle">COMPETE </div>
                            <div class=" bfMonth align-middle"><span class="hiwHeadingTwo align-middle">For those that code</span></div>
                        </div>
                        <div class="col-sm-4 text-right">
                            <button type="button" class="btn btn-outline-yellow waves-effect float-right" routerLink="/leaderboard"><a style="color:black;">Leaderboard</a></button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="bfMonthDesc col"><p>These will be 10min coding comps that run where everyone competes against each other and earns points for 
                            the leader board. These participants will be given the “Hello World”, Presented in the CODE session, as a PDF they can quickly breeze 
                            through to get going in the COLLABORATE section later. The comps are for participants that are already well versed with software and know 
                            how to complete the ‘Hello World’ of the chosen language. The CODE and COMPETE section run in parallel for the first hour of the event. </p>
                        </div>
                    </div>
                </div>
            </div> -->
  
    </div>
    </div>
</div>

<div class="row" >
    <div class="container">     
        <div class="card large">
            <div class="card-body">
                <div class="row">
                    <div class="col-8 text-left align-middle">
                        <div class=" bfMonth align-middle">COLLABORATE <span class="hiwHeadingTwo align-middle">For all coders!</span></div>
                    </div>
                    <div class="col-4 text-right">
                        <!--<button type="button" class="btn btn-outline-yellow waves-effect float-right" disabled><a style="color:black;">View Projects</a></button>-->
                    </div>
                </div>

                <div class="row">
                    <div class="bfMonthDesc col"><p>Interactive session where all members form groups and work on writing some code together to compete 
                        with other groups at the end or just generally complete the challenges set out. This follows a pizza break after the first hour. 
                        The goal for this section is to work together to write code. This is where the pros can teach the new comers. The challenges are 
                        designed by the presenter, in collaboration with the Blast Furnace organisers.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row " style="padding-top:1%">
    <div class="container flex-center">
        <div class="intro-info-content text-center">
            <h6 class="NextEvent" data-wow-delay="0.3s">GET INVOLVED</h6>
            <h6 class="NextEventBlack" data-wow-delay="0.3s">We’d love to see you at our next event</h6>
        </div>
    </div>
</div>
<div class="row" style="padding-top:1%">
    <div class="container">
        <div class="card large" >
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="bfMonth">CYFERLINX</div>
                        <div class="bfMonthDesc">All Blastfurnace attendee’s will keep track of their progress through their free CYFERLinx profile. Sign up before you come or we can 
                            sign you up on the day to access lessons, projects, keep track of your points & view the leaderboard.</div>
                        <div class="row justify-content-md-center">
                            <div class="col" style="text-align: center">
                                <button type="button" class="btn btn-yellow waves-effect" href="https://cyferlinx.com/signup" ><a style="color:white">Create Free Account</a></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="bfMonth">FOLLOW US</div>
                        <div class="bfMonthDesc">Keep up to date with Blastfurnace through our social accounts. We’ll post our upcoming events and news</div>
                        <div class="row justify-content-md-center">
                                <div class="col" style="text-align: center">
                                    <!--<button type="button" class="btn btn-yellow waves-effect" disabled><a style="color:white">Facebook</a></button>
                                    <button type="button" class="btn btn-yellow waves-effect" disabled><a style="color:white">LinkedIn</a></button>-->
                                    <button type="button" class="btn btn-yellow waves-effect"  
                                    onclick="window.location.href='https://blast-furnace.slack.com/join/shared_invite/enQtMzg0NjA0MzQ4OTQ4LTBkNmU1OWE4MTYyNGIwNDBjYzQzNTliZWY2ZmZmZDA0YTk2MzRkNjQxMWU5ZTc1M2M4ZjU2NWY3M2M3ZjFkMzI'">
                                    <a style="color:white"><i class="fab fa-slack"></i> Slack</a></button>
                                    
                                    <button type="button" class="btn btn-yellow waves-effect"  
                                        onclick="window.location.href='https://www.meetup.com/Blast-Furnace/'"> 
                                    <a style="color:white"><i class="fab fa-meetup"></i> MEET UP</a> </button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row " style="padding-top:2%">
    <div class="container flex-center">
        <div class="intro-info-content text-center">
            <div class="NextEvent" data-wow-delay="0.3s">SUPPORTERS</div>
        </div>
    </div>
</div>

<div class="row" style="padding-top:1%">
    <div class="container" >
        <div class="card large" >
            <div class="card-body">

                    <!-- FOR EACH SPONSOR -->
                        <div *ngFor="let sponsor of sponsorsArray; let i = index">
                            <div *ngIf="sponsor.companyName">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="bfMonthDesc"><P>The Blastfurnace is proudly supported by {{sponsor.companyName}}.</P>
                                                <p>{{sponsor.companyDescription}}</p></div>
                                            <div class="row justify-content-md-center">
                                                <div class="col" style="text-align: center">
                                                <button type="button" class="btn btn-outline-yellow waves-effect"  (click)="DownloadFile(sponsor.companyLink)"><a style="color:black">Visit {{sponsor.companyName}}</a></button>
                                              
                                            </div>
                                            </div>
                                    </div>

                                <div class="col-sm-6">
                                    <div class="container flex-center" *ngIf="sponsor.companyImageUrl">
                                        <!--<div *ngFor"let image of bfData.supporterImageUrl; let k = index">-->
                                            <img class="" style="width: 80%" [src]="sponsor.companyImageUrl">
                                        <!--</div>--> 
                                    </div> 
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    <div class="intro-info-content text-center">
        <img class="image" src="../../../assets/images/image.png">
    </div>
    
<div *ngIf="!user">
    <register></register>
</div>


        
 
    
  
