<div class="container" style="background-color: white; padding-top: 3%">

    
    <h1>Website Terms And Conditions Of Use</h1>
    <p>In these terms and conditions, “we” “us” and “our” refers to Saphi Pty Ltd. 
        These terms and conditions of use and any terms specific to a particular webpage or 
        interactive area (<b>Site Terms</b>) apply to your use of the website <a routerLink="/" class="hackerLinxBlue">https://www.cyferlinx.com</a> (<b>Site</b>) 
        and do not alter in any way the terms or conditions of any other agreement you may have with us. 
      </p>
      <p>
        Please read these Site Terms carefully. By visiting or using the Site including any of the services offered on the Site, 
        you agree to be bound by the Site Terms and by all terms, policies, guidelines, notices and disclaimers published on this Site 
        and any documents available for download from the Site. If you do not agree to all of these terms, do not use the Site. 
        By using this Site, you represent and warrant that you are over the age of 18 and are lawfully able to accept these Site Terms.
    </p>

    <h2>Privacy Policy</h2>
    <p>
        Saphi Pty Ltd is committed to protecting your privacy. 
        Please refer to our <a routerLink="/terms/privacy" class="hackerLinxBlue"><strong>Privacy Policy </strong></a> 
        for information on how we collect, use and disclose personal information. 
    </p>

    <h2>Ownership of the Site and its Contents</h2>
    <p>
        The Site is owned by Saphi Pty Ltd.  Unless otherwise indicated, Saphi Pty Ltd owns or has a licence 
        to use all of the content featured or displayed on the Site, including, but not limited to 
        text, graphics, designs, artworks, data, photographic images, moving images, sound, illustrations, software, 
        and their selection and arrangement (<b>Site Content</b>).
    </p>
    <p>
        All elements of the Site, including the Site Content, are protected by laws relating to the protection 
        of intellectual property such as copyright and trade mark laws.
    </p>

    <h2>Use of the Site</h2>
    <p>
        You may only use this Site or the Site Content for the purpose of using our services including engaging with others via this Site. 
        You are permitted to download a copy of the information on this Site to your computer or personal mobile device for 
        your personal use only provided that you do not delete or change any copyright symbol, trade mark or other proprietary notice.  
    </p>
    <p>
        Your use of our content in any other way infringes our intellectual property rights. You are specifically prohibited from: 
        (a) using any data mining, robots or similar data gathering or extraction methods; and 
        (b) manipulating or otherwise displaying the Site or the Site Content by using framing or similar navigational technology. 
    </p>
    <p>
        You represent and warrant that you will comply with all applicable laws and regulations, including, without limitation, 
        those relating to the Internet, data, e-mail, privacy, and the transmission of technical data exported from Australia or 
        the country in which you reside.
    </p>

    <h2>Copyright Infringement Policy</h2>
    <p>
        In accordance with applicable copyright legislation and other applicable laws, Saphi Pty Ltd has a policy of terminating, 
        in appropriate circumstances and at Saphi Pty Ltd's sole discretion, account holders who infringe the intellectual property rights 
        of Saphi Pty Ltd or any third party.
    </p>
    <p>
        If you believe that any material on the Site infringes your copyright, you may send a notification of such infringement to us at 
        <a href="https://www.saphi.com.au/contact-us/" class="hackerLinxBlue">contact@saphi.com.au</a>. 
        We may give notice of a claim of copyright infringement by means of a general notice on the Site or electronic mail 
        to a user's e-mail address.
    </p>

    <h2>Trade marks</h2>
    <p>
        Saphi Pty Ltd is the owner or licensee of registered and unregistered trade marks as identified on the Site and these may not be copied, 
        imitated or used, in whole or in part, without the prior written permission of Saphi Pty Ltd. In addition, 
        Saphi Pty Ltd owns the look and feel of the Site, including all page headers, custom graphics, button icons and scripts, and it may not be copied, 
        imitated or used, in whole or in part, without our prior written permission. All other trade marks, product names and company names or logos 
        used on the Site are the property of their respective owners. Our reference to any third party’s products or services is not an endorsement, 
        sponsorship or recommendation of its products or services.
    </p>

    <h2>Links</h2>
    <p>You may not use our logo or other proprietary graphic of Saphi Pty Ltd to link to this Site without our express written permission. Further, 
      you may not frame any of our trade marks, logos or other proprietary information, including the Site Content, without our express written permission.
    </p>
    <p>
        This Site may contain links to third party sites which are not under Saphi Pty Ltd’s control and Saphi Pty Ltd has no responsibility for the content of these sites.  
        Saphi Pty Ltd makes no claim or representation regarding, and accepts no responsibility for, directly or indirectly, the quality, content, nature or 
        reliability of third-party websites accessible by link from the Site, or websites linking to the Site. Links to other sites are provided for convenience only, 
        and unless we expressly state otherwise, do not imply any affiliation, endorsement or adoption by Saphi Pty Ltd of the products or services offered by the site. 
    </p>
    <p>
        When you leave the Site, the Site Terms no longer apply. You should review the applicable terms and policies, including privacy and data gathering practices, 
        of any site to which you navigate from the Site. You agree that Saphi Pty Ltd is not responsible or liable for any loss, damage or other matters of any sort 
        incurred as the result of your dealings with third parties via their websites.
    </p>

    <h2>Forums & Other Interactive Services or Areas </h2>
    <p>
        The Site includes discussion forums and possibly other interactive areas or services, including blogs, chat rooms, bulletin boards, message boards, 
        online hosting or storage services, or other areas or services (<b>Interactive Areas</b>) in which you or third parties create, post or store any content, 
        messages, comments, materials or other items on the Site (<b>User Content</b>). You are solely responsible for your use of such Interactive Areas and use 
        them at your own risk. You must not delete or revise any User Content that you have not posted. When you post content to an Interactive Area, you 
        represent and warrant that you have the lawful right to transmit, distribute and reproduce such User Content.
    </p>
    <p>
        You agree not to post, upload, transmit, distribute, store, create or otherwise publish through the Site any of the following:
    </p>
    <ol type="a">
        <li>
          User Content that is unlawful, libellous, defamatory, obscene, pornographic, harmful to minors, indecent, lewd, suggestive, harassing, 
          threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent or otherwise objectionable;
        </li>
        <li>
          User Content that constitutes, encourages or provides instructions for a criminal offense or violates the rights of any person or entity;
        </li>
        <li>
            User Content that may infringe any intellectual property or contractual rights of any person or entity;
        </li>
        <li>
            User Content that impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity;
        </li>
        <li>
            unsolicited promotions, political campaigning, advertising, junk mail, spam, chain letters, pyramid schemes or solicitations;
        </li>
        <li>
            personal or sensitive information of any third party;
        </li>
        <li>
            viruses, corrupted data or other harmful, disruptive or destructive files; and
        </li>
        <li>
            User Content that, in our sole judgment is objectionable or which may expose Saphi Pty Ltd or its users to any harm or liability of any type.
        </li>
      </ol>
      <p>
          Saphi Pty Ltd is not liable for any statements, representations or User Content provided by its users in any public forum, personal home page or 
          other Interactive Area. Saphi Pty Ltd reserves the right to remove, screen or edit any User Content posted or stored on the Site at any time and 
          for any reason without notice. You are solely responsible for creating backup copies of and replacing any User Content you post or store on the 
          Site at your sole cost and expense. Any use of the Interactive Areas or other portions of the Site in breach of the Site Terms may result in, 
          among other things, termination or suspension of your rights to use the Interactive Areas and/or the Site. You acknowledge and agree that 
          Saphi Pty Ltd may access, use or disclose any information about you or your use of this Site, including without limitation any User Content to 
          comply with the law or any legal process; protect and defend the rights or property of Saphi Pty Ltd; or to protect the safety of our employees, 
          customers or the public.
      </p>
      <p>
          You represent and warrant that (a) you own and control all of the rights to the User Content that you post or you otherwise have the right to post 
          such User Content to the Site; (b) the User Content is accurate and not misleading; and (c) use and posting of the User Content you supply does not 
          violate these Site Terms and will not violate any rights of or cause injury to any person or entity.
      </p>
      <p>
          If you post User Content to the Site, unless we indicate otherwise, you grant Saphi Pty Ltd and its affiliates a nonexclusive, royalty-free, perpetual, 
          irrevocable and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and 
          display such User Content throughout the world in any media. You grant Saphi Pty Ltd and its affiliates and sub-licensees the right to use the name 
          that you submit in connection with such content, if they choose. You consent to giving us this licence even where such acts in relation to the 
          correspondence or communication involve (a) a failure to identify you as the author; (b) the false attribution of another person as the author; 
          and (c) the subjection of them to derogatory treatment; whether occurring before or after the giving of consent, and which apart from this consent, 
          infringe any moral rights subsisting in the correspondence or communication.
      </p>

      <h2>Feedback</h2>
      <p>
        Please use the contact form on Saphi Pty Ltd’s website to provide any feedback or complaints in regards to Saphi Pty Ltd
      </p>
      <p>
        Saphi Pty Ltd is entitled to suspend or terminate your account at any time if Saphi Pty Ltd, in its sole and absolute discretion, is concerned by any 
        feedback that is posted in relation to candidates, recruiters or employers.
      </p>

      <h2>Registration Data and Account Security</h2>
      <p>
          In consideration of your use of the Site, you agree to: (a) provide accurate, current and complete information about you as may be prompted by any 
          registration forms on the Site (Registration Data); (b) maintain and promptly update the Registration Data, and any other information you provide 
          to Saphi Pty Ltd, to keep it accurate, current and complete; (c) maintain the security of your password and identification; (d) notify us immediately 
          of any unauthorised use of your account or other breach of security; (e) accept all responsibility for any and all activities that occur under your 
          account; and (f) accept all risks of unauthorised access to the Registration Data and any other information you provide to us.
      </p>

      <h2>Indemnification</h2>
      <p>
          You agree to defend, indemnify and hold harmless Saphi Pty Ltd, its subsidiaries, affiliates, licensors, employees, agents, third party information 
          providers and independent contractors against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable legal costs) 
          arising out of or related to any User Content that you post, store or otherwise transmit on or through the Site, your conduct, use of or inability to use 
          the Site, your breach or alleged breach of the Site Terms or of any representation or warranty contained herein, your unauthorised use of the Site Content, 
          or your violation of any rights of another.
      </p>
      
      <h2>Disclaimer</h2>
      <p>
        Saphi Pty Ltd uses reasonable efforts to ensure the accuracy, correctness and reliability of the Site Content, but we make no representations or warranties 
        as to the Site Content's accuracy, correctness or reliability.
      </p>
      <p>
          To the maximum extent permitted by applicable law, the Site and the Site Content are provided "as is" without guarantee or warranty, express or implied, 
          of any kind including but not limited to guarantees or warranties of acceptable quality or fitness for a particular purpose or non-infringement. 
          You agree that the functions embodied on or in the materials of the Site are not warranted to be uninterrupted or without error or that defects will be 
          corrected. We make no warranty that the Site or the Site Content are free from infection by viruses or anything else that has contaminating or destructive 
          properties.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
          To the extent that Saphi Pty Ltd can limit the user’s remedy for breach, then Saphi Pty Ltd’s liability is limited to one or more of the following at 
          Saphi Pty Ltd’s option:
      </p>
      <ol type="A">
          <li>
              in the case of goods, the replacement of the goods or the supply of equivalent goods, the repair of the goods, the payment of the cost of 
              replacing the goods or of acquiring equivalent goods, or the payment of the cost of having the goods repaired; or
          </li>
          <li>
              in the case of services, the supplying of the services again, or the payment of the cost of having the services supplied again.
          </li>
        </ol>
        <p>
            To the maximum extent permitted by applicable law, in no event shall Saphi Pty Ltd and its directors, employees, content providers, agents and 
            affiliates be liable for any direct, special, indirect or consequential damages, or any other damages of any kind, including but not limited to 
            loss of use, loss of profits or loss of data, whether in an action in contract, tort (including but not limited to negligence) or otherwise, 
            arising out of or in any way connected with the use of the Site, the services or products, the Site Content or the materials contained in or 
            accessed through the Site, including without limitation any damages caused by or resulting from reliance by user on any information obtained 
            from Saphi Pty Ltd, or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in 
            operation or transmission or any failure of performance, whether or not resulting from acts of god, communications failure, theft, destruction or 
            unauthorised access to Saphi Pty Ltd’s records, programs or services.
        </p>
        <p>
            To the maximum extent permitted by applicable law, in no event shall the aggregate liability of Saphi Pty Ltd, whether in contract, guarantee, 
            warranty, tort (including negligence), product liability, strict liability or otherwise, arising out of or relating to the use of the Site exceed 
            the fees you pay, if any, to Saphi Pty Ltd for access to or use of the site.
        </p>

        <h2>Termination</h2>
        <p>
            Notwithstanding any of these Site Terms, Saphi Pty Ltd reserves the right, without notice and in its sole discretion, to terminate your account 
            and/or to block your use of the Site. On termination you must destroy all materials obtained from the Site and any and all other Saphi Pty Ltd sites 
            including copies of those materials.
        </p>

        <h2>Changes to Site Terms</h2>
        <p>
            Saphi Pty Ltd reserves the right to change any of the terms and conditions contained in the Site Terms or any policy or guideline of the Site, at any 
            time and in its sole discretion. Any changes will be effective immediately upon posting on the Site. Your continued use of the Site following the 
            posting of changes will constitute your acceptance of such changes. We encourage you to review the Site Terms whenever you visit one of our websites.
        </p>

        <h2>Miscellaneous Provisions</h2>
        <p>
          Any waiver of any provision of the Site Terms will be effective only if in writing and signed by Saphi Pty Ltd. 
        </p>
        <p>
          The Site Terms are governed by the laws of New South Wales and the parties submit to the non-exclusive jurisdiction of the courts of New South Wales.
        </p>
        <p>
            If part or all of any of the Site Terms is illegal or unenforceable it will be severed from the Site Terms and will not affect the continued operation 
            of the remaining provisions of the Site Terms.
        </p>

        <h2>Questions and Contact Information</h2>
        <p>
            Questions or comments about the Site or Site Terms may be directed to Saphi Pty Ltd at <a href="https://www.saphi.com.au/contact-us/" class="hackerLinxBlue">contact@saphi.com.au</a>. 
        </p>
  </div>