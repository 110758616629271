import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'youtube-modal',
  templateUrl: './youtube-modal.component.html',
  styleUrls: ['./youtube-modal.component.scss']
})
export class YoutubeModalComponent implements OnInit {
  @Input() url: string;
  video
  constructor(
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    //console.log(this.url)
    this.video = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
  }

}
