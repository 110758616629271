import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
//import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../environments/environment';
import { NavService } from './nav.service';
import { FooterNavComponent } from '../footer-nav/footer-nav.component';
import { NotificationMessageComponent } from '../notification-message/notification-message.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';
import { TermsComponent } from '../terms/terms.component';
import { TermsOfUseComponent } from '../terms/terms-of-use/terms-of-use.component';
import { PrivacyComponent } from '../terms/privacy/privacy.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { ToastModule } from 'ng-uikit-pro-standard';
import { YoutubeModalComponent } from '../youtube-modal/youtube-modal.component';
import { MatAutocompleteModule, MatFormFieldModule } from '@angular/material';
import { LoginComponent } from '../login/login.component'
import { BlastFurnaceNavComponent } from '../blast-furnace-nav/blast-furnace-nav.component';
export const googleAPIKey = environment.googleAPIKey;
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    MatAutocompleteModule, 
    MatFormFieldModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    //AgmCoreModule.forRoot({ apiKey: googleAPIKey, libraries: ["places"] }),
  ],
  declarations: [
    FooterNavComponent,
    NotificationMessageComponent,
    PageNotFoundComponent,
    TermsComponent,
    TermsOfUseComponent,
    PrivacyComponent,
    YoutubeModalComponent,
    LoginComponent,
    BlastFurnaceNavComponent,
  ],
  exports: [
    FooterNavComponent,
    NotificationMessageComponent,
    BlastFurnaceNavComponent,
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class UiModule { }
