

<div>
      <div class="full-bg-img " style="vertical-align: middle;">
            <br>
            <div class="container flex-center">
            <div class="intro-info-content text-center" >
                  <h6 class="saphi">© All Rights Reserved  |  Saphi Software Development 2017-19</h6>
                  <h6 class="saphi"><a routerLink="/terms/use">Terms of Use</a>  |  <a routerLink="/terms/privacy">Privacy Policy</a></h6>
            </div>
      </div>
      </div>
  </div>
