<!--<div  class="image" style="background-image: url(../../../assets/images/cloudsSmall.jpg);">-->
<body style="background-color:#1c2a48">
<div>
    <!--<div class="full-bg-img">-->
        <div class="container flex-center">
            <div class="row pt-5 mt-3">
                <div class="col-md-12 mb-3">
                    <div class="intro-info-content text-center">
                        <!--<h1 class="display-3 mb-3 titlefont " data-wow-delay="0.3s">Cyfer<a class="hackerlinxGreen font-bold ">Linx</a></h1>-->
                        <img src="../../../assets/images/Logo.svg" style="width:100%; padding-bottom:3%">
                        <h4 class=" mb-4 mt-1" style="color:white">Coming Soon</h4>
                        <a class="btn hackerlinxGreenBG accent-4 btn-lg" ripple-radius href="http://saphi.com.au/index.php/contact-us/">Contact Us</a> 
                        
                            
                    </div>
                </div>
            </div>
        </div>
    </div>
    </body>

