<a class="btn accent-4 btn-lg outline"  data-toggle="modal" data-target="#basicExample" (click)="frame.show()">
  <i class="fa fa-video-camera" aria-hidden="true"></i> WATCH VIDEO</a>
<!--<button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal" data-target="#basicExample" (click)="frame.show()"
  mdbWavesEffect>WATCH VIDEO</button>-->

<div mdbModal #frame="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" style="overflow: auto">
  <div class="modal-dialog modal-lg" role="document">

    <!--Content-->
    <div class="modal-content">

      <!--Body-->
      <div class="modal-body mb-0 p-0">
        <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
          <iframe class="embed-responsive-item e2e-trusted-url" [src]='video' allowfullscreen></iframe>
        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center flex-column flex-md-row" style="background-color:#1c2a48">
        <a class="btn accent-4 btn-lg hackerlinxGreenBG" data-dismiss="modal" (click)="frame.hide()">CLOSE</a>
        <!--<button type="button" mdbBtn color="primary" outline="true" rounded="true" class="ml-4 waves-light" mdbWavesEffect data-dismiss="modal"
          (click)="frame.hide()">Close</button>-->

      </div>

    </div>
    <!--/.Content-->

  </div>
</div>