<!-- 
<div class="container flex-center">
<div *ngIf="notify.msg | async as msg" class="card card-image mb-3">
  

      <div class="text-black text-center d-flex align-items-center rgba-white-strong py-5 px-4">
          <div>
              <p>{{ msg.content }}</p>
              <a [ngClass]="{ 
                'btn btn-danger waves-light': msg.style == 'error',
                'btn btn-success waves-light': msg.style == 'success',
                'btn btn-info waves-light': msg.style == 'info'
              }"  
              (click)="notify.clear()" mdbRippleRadius>CLEAR</a>
          </div>
      </div>

  </div>
</div> -->

<!-- Card -->
<div class="card  mb-3" *ngIf="notify.msg | async as msg" >
  
      <!-- Content -->
      <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4">
          <div>

              <p>{{ msg.content }}</p>
              <a [ngClass]="{ 
                'btn btn-danger waves-light': msg.style == 'error',
                'btn btn-success waves-light': msg.style == 'success',
                'btn btn-info waves-light': msg.style == 'info'
              }" mdbRippleRadius>Clear</a>
          </div>
      </div>
      <!-- Content -->
  </div>