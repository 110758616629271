
 <div class="blast-furnace">
<div class="full-bg flex"  style="background-image: url(../../assets/images/blastfurnace.jpg); background-size: cover; ">
    
    <blast-furnace-nav [enableLogo]="true"></blast-furnace-nav>   

</div>
<div class="container">

  <div class="card">
    <!--<div class="card-header deep-orange lighten-1 white-text">-->
      <!--Featured-->
    <!--</div>-->
    <div class="card-body">
      <h4 class="card-title">Blast Furnace Admin - Add Event Results</h4>
      <div class="form-inline">

        <div class="md-form form-group">
          <i class="fa fa-user prefix"></i>
          <input mdbActive type="number" class="form-control" id="form1" [(ngModel)]="totalCompetitors" readonly mdbInputValidate>
          <label class="active" for="form1">Number of competitors</label>
        </div>
        <div class="md-form form-group">
          <i class="fa fa-diamond prefix"></i>
          <input mdbActive type="number" class="form-control" id="form2" (keyup)="recalculateScores()" [(ngModel)]="pointsPerCompetitor"  mdbInputValidate>
          <label class="active" for="form2">Points per competitor</label>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngFor="let place of places.slice(0, totalCompetitors)">
    <div *ngIf="place.name!='Participant'">
    <div class="card-header deep-orange lighten-1 white-text">
      {{place.name}}
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <div class="md-form form-group">
            <i class="fa fa-user prefix"></i>
            <input mdbActive type="text" class="form-control" id="form10" name="player1" [(ngModel)]="place.nameQuery" (keyup)="place.users = search(place.nameQuery)" mdbInputValidate>
            <label class="active" for="form10">Competitor Name</label>
          </div>
          <div *ngIf="place.name=='First'">
            <div class="md-form form-group">
              <i class="fa fa-times prefix"></i>
              <input mdbActive type="number" class="form-control" id="form11" name="multiplyer" [(ngModel)]="multiplier" (keyup)="recalculateScores()">
              <label class="active" for="form11">Multiplier</label>
            </div>
          </div>

          <div *ngFor="let user of place.users.slice(0,3)">
            <div class="mini-card"  (click)="place.user = users[user]" [style.background-color]="users[user] == place.user ? '#C5E1A5' : '#EEEEEE'">

              <div class="avatar" *ngIf="users[user] && users[user].photoURL != null"><img src="{{users[user].photoURL}}" class="rounded-circle"></div>
              <span class="card-title" *ngIf="users[user]">{{users[user].firstName}} {{users[user].lastName}}</span>
              <i *ngIf="users[user] == place.user" class="fa tick fa-check"></i>
            </div>

          </div>
        </div>
        <div class="col-6">
          <h5>Calculated points: <b>{{place.score}}</b></h5>
        </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card">
    <!--<div class="card-header deep-orange lighten-1 white-text">-->
    <!--Featured-->
    <!--</div>-->
    <div class="card-body">
      <h4 class="card-title">Submit Event</h4>
      <form class="form-inline">
        <button type="button" class="btn btn-primary waves-light" (click)="save()" mdbRippleRadius>Submit Scores</button>
      </form>
    </div>
  </div>
</div>
