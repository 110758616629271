
<div class="view jarallax" style=" background-color: #1c2a48;background: cover;">
  <div class="full-bg-img">
      <div class="container flex-center">
          <div class="row pt-5 mt-3">
              <div class="col-md-12 mb-3">
                  <div class="intro-info-content text-center">
                    <div class="container flex-center">
                        <img class="Logo" style="width: 20%" src="../../../assets/images/cyferlinx-lynx-logo-05.svg">
                    </div>
                     <br>
                      <h1 class="display-3 mb-5 titleFont" style="color: white"data-wow-delay="0.3s">PAGE NOT FOUND</h1>
                      <a class="btn hackerlinxGreenBG accent-4 btn-lg" ripple-radius routerLink="/">Go Back to Home</a> 
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

