<div class="blast-furnace">
<div class="full-bg flex"  style="background-image: url(../../assets/images/blastfurnace.jpg); background-size: cover; ">
    
  <blast-furnace-nav [enableLogo]="true"></blast-furnace-nav>   
</div>
<div class="container">

  <div class="card" style="margin: 0.5rem 0">
    <!--<div class="card-header deep-orange lighten-1 white-text">-->
    <!--Featured-->
    <!--</div>-->
    <div class="card-body">
      <h4 class="card-title">Blast Furnace Admin - Add Event</h4>
      <form class="form-inline right">
        <button type="button" class="btn btn-primary waves-light" (click)="save()" mdbRippleRadius>Submit Event</button>
      </form>

    </div>
  </div>

  <div class="card lowPadding">
    <div class="card-body"*ngFor="let competitor of competitors">
          <div class="md-form input-group" style="width: 100%; display: flex">

            <input class="input-group-addon" placeholder="Email" id="basic-addon3 share-link" [(ngModel)]="competitor.email" (keyup)="setUserByEmail(competitor)">
            <div class="middle">
              <div *ngIf="competitor.user != null">
                {{competitor.user.firstName}} {{competitor.user.lastName}}
                <img src="{{competitor.user.photoURL}}"/>
              </div>
            </div>
            <input class="input-group-addon" placeholder="Language" id="basic-addon3" [(ngModel)]="competitor.languageInput" (keyup)="setLanguage(competitor)">
            <div class="middle">
                {{competitor.language}}
            </div>
            <div class="delete" (click)="delete(competitor)">
              <i class="fa fa-trash"></i>
            </div>

          </div>
    </div>
  </div>

  <div class="card lowPadding">
    <div class="card-body">
      <form class="form-inline right">
        <button type="button" class="btn btn-primary waves-light" (click)="addCompetitor()" mdbRippleRadius>Add Competitor</button>
        <button *ngIf="previous == false" type="button" class="btn btn-primary waves-light" (click)="previousCompetitors()" mdbRippleRadius>Previous Event Competitors</button>
      </form>
    </div>
  </div>

</div>
</div>
