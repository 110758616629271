import { NgModule } from '@angular/core';
import { AdminService } from './admin.service'
import { AuthService } from './auth.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { NotifyService } from './notify.service';
import { BlastFurnaceService } from './blastfurnace.service';
import { NavbarService } from './navbar.service';
import { StackService } from './stack.service';
@NgModule({
  imports: [
    AngularFireAuthModule,
    AngularFirestoreModule
  ],
  providers: [AdminService,
              AuthService,
              NotifyService,
              BlastFurnaceService,
              NavbarService,
              StackService,
            ],
  declarations: []
})
export class CoreModule { }

