<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark">
    <logo>
        <a class="navbar-brand" routerLink="/">
            <img class="" height="30" class="d-inline-block align-top" alt="" src="../../../assets/images/bf-navbar-logo.svg">
        </a>
    </logo>
    <links>
        
        <ul class="navbar-nav mr-auto"> 
            <li class="nav-item" ripple-radius>
                <a class="nav-link" routerLink="/"> About <span class="sr-only"></span></a>
            </li>                              
<!--             <li class="nav-item" ripple-radius>
                <a class="nav-link" routerLink="/leaderboard"> Leaderboard <span class="sr-only"></span></a>
            </li> -->
            <li class="nav-item" ripple-radius>
                <a class="nav-link" routerLink="/lessons"> Lessons <span class="sr-only"></span></a>
            </li>
<!--             <li class="nav-item" ripple-radius>
                <a class="nav-link" routerLink="/history"> Comp Event History <span class="sr-only"></span></a>
            </li>    -->  
            <li class="nav-item" ripple-radius>
                <a class="nav-link titlefont" href="https://www.cyferlinx.com">CYFER<span class="titlefontGreen">LINX</span></a>
            </li>  
        </ul> 
    </links>
</mdb-navbar>                       
