import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID} from '@angular/core';
import { NavbarService } from '../core/navbar.service';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore} from '@angular/fire/firestore';

import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { User, Sponsor } from '../core/user';
import { AuthService } from '../core/auth.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'blast-furnace',
  templateUrl: './blast-furnace.component.html',
  styleUrls: ['./blast-furnace.component.scss']
})
export class BlastFurnaceComponent implements OnInit, OnDestroy {
  downloadURL:any;
  userSubscribe: Subscription;
  user: User;
  blastfurnace:Subscription;
  lat:number;
  long:number;
  lessonLink;
  fundamentalsLink;
  bfData:any;
  public map: any = { lat: -32.929454, lng: 151.764561, zoom: 15 };
  video
  isBrowser: boolean;
  sponsorsArray: Sponsor[];

  constructor(public nav: NavbarService,
              private storage: AngularFireStorage,
              private db: AngularFirestore,
              public sanitizer: DomSanitizer,
              private title:Title, 
              private meta:Meta,
              private auth: AuthService,
              @Inject(PLATFORM_ID) platformId: Object,) {
              this.isBrowser = isPlatformBrowser(platformId);
   }

  ngOnInit() {
    this.nav.hide();
    this.title.setTitle('Blast Furnace')
    this.meta.addTags([
      { property: 'og:site_name', conent: environment.metadata.name},
      { property: 'og:url', content: environment.metadata.url + ''},
      { property: 'og:title', content: 'Blast Furnace'},
      { property: 'og:description', content: 'The Blast Furnace is a monthly meetup, competition & education session aimed at all levels of programmers.'},
      { property: 'og:image', content: environment.metadata.image},
      { property: 'og:type', content: 'website'},
      { property: 'og:video', content: environment.metadata.video},
      { name: 'description', content: 'The Blast Furnace is a monthly meetup, competition & education session aimed at all levels of programmers.'},
      { name: 'twitter:card', content: 'summary'},
      { name: 'twitter:site', content: environment.metadata.site},
      { name: 'twitter:title', content:environment.metadata.name},
      { name: 'twitter:description', content: 'The Blast Furnace is a monthly meetup, competition & education session aimed at all levels of programmers.'},
      { name: 'twitter:image', content: environment.metadata.image}
    ])
    //console.log("Is browser: " + this.isBrowser);
    this.userSubscribe = this.auth.currentUser.subscribe(user => {
      this.user = user;
    });
    const path = `admin/blastFurnace`; 
    this.blastfurnace = this.db.doc(path).valueChanges().subscribe(data =>{
        if (data){
          this.bfData = data
          this.lat = parseFloat(JSON.stringify(this.bfData.mapLocation.latitude))
          this.long = parseFloat(JSON.stringify(this.bfData.mapLocation.longitude))
          this.lessonLink = this.bfData.lessonLink
          this.fundamentalsLink = this.bfData.fundamentalsLink
          this.video = this.sanitizer.bypassSecurityTrustResourceUrl(this.bfData.lessonVideo)
          this.sponsorsArray = this.bfData.sponsorsArray;
         // let long = this.bfData.mapLocation.getLongitude()
          //console.log(this.bfData)
        }
      })
  }
  
  DownloadFile(fileName){
  
      /*this.downloadURL = this.storage.ref(fileName).getDownloadURL()
      this.downloadURL.subscribe(url =>{
        if(url){
          window.open(fileName,"_self")
        }
      })*/
      window.open(fileName,"_self")
  }
  videoURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.bfData.lessonVideo)
  }

  ngOnDestroy(){
    if(this.blastfurnace){
      this.blastfurnace.unsubscribe()
    }
  }

}
