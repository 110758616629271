import { Component, OnInit, ViewChild  } from '@angular/core';
import { BlastFurnaceService } from '../core/blastfurnace.service';
import { AngularFirestore } from '@angular/fire/firestore';
import {AuthService} from '../core/auth.service';
import { Subscription ,  Observable } from 'rxjs';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { User, StackUser } from '../core/user';
import { StackService } from '../core/stack.service'
import { environment } from '../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'blast-furnace-leaderboard',
  templateUrl: './blast-furnace-leaderboard.component.html',
  styleUrls: ['./blast-furnace-leaderboard.component.scss']
})
export class BlastFurnaceLeaderboardComponent implements OnInit {



 // start = new Date('2015');
  //end = new Date('2200');
  start : any;
  end : any;
  startConverted: any;
  endConverted: any;
  events: string[] = [];
  show: any;
  pos:any;

  eventSub = null;
  competitors = {};
  competitorsArray = [];
  users = {};
  StackUser: StackUser;
  eventSubscribe: Subscription;
  event2Subscribe: Subscription;
  event3Subscribe: Subscription;
  userSubscribe: Subscription;
  user4Subscribe: Subscription;
  user5Subscribe: Subscription;
  downloadURLSubscribe: Subscription;
  stackSubscribe: Subscription;
  blastfurnace:Subscription;
  public daterange: any = {};
  avatarPath: string ="";
  downloadURL: Observable<string>;
  bfData:any
  constructor(private blastFurnaceService: BlastFurnaceService, private auth: AuthService, private storage: AngularFireStorage, private db: AngularFirestore, private stack: StackService, private title:Title, private meta:Meta,) {
  
  }
  

  ngOnInit() {
    this.title.setTitle('Blast Furnace')
    this.meta.addTags([
      { property: 'og:site_name', conent: environment.metadata.name},
      { property: 'og:url', content: environment.metadata.url + ''},
      { property: 'og:title', content: 'Blast Furnace Leaderboard'},
      { property: 'og:description', content: 'The Blast Furnace Leaderboard shows the rankings of the code comps.'},
      { property: 'og:image', content: environment.metadata.image},
      { property: 'og:type', content: 'website'},
      { property: 'og:video', content: environment.metadata.video},
      { name: 'description', content: 'The Blast Furnace Leaderboard shows the rankings of the code comps.'},
      { name: 'twitter:card', content: 'summary'},
      { name: 'twitter:site', content: environment.metadata.site},
      { name: 'twitter:title', content: 'Blast Furnace Leaderboard'},
      { name: 'twitter:description', content: 'The Blast Furnace Leaderboard shows the rankings of the code comps.'},
      { name: 'twitter:image', content: environment.metadata.image}
    ])
    this.onChange2()
    const path = `admin/blastFurnace`; 
    this.blastfurnace = this.db.doc(path).valueChanges().subscribe(data =>{
        if (data){
          this.bfData = data
        }
      })
  }
  ngOnchanges(){
    //this.onChange2()
  }

  onChange2(){
    this.show = false;
    let language = "Total"
    this.eventSubscribe = this.blastFurnaceService.getTotalLeaderboard(language).subscribe(leaderboard => {
      if(leaderboard){
        let leaders = leaderboard;
        let length = leaderboard.length;
        //console.log(leaders);
        this.competitorsArray = [];
        this.competitorsArray=leaders;
        this.users = {};
        //console.log(this.competitorsArray);
        this.pos=1;
        for (let i=0; i<length; i++){
          if (!(this.competitorsArray[i].uid in this.users)) {
            this.userSubscribe = this.auth.getUserByIDString(this.competitorsArray[i].uid).subscribe(aUser => {
              if(aUser){
                this.users[this.competitorsArray[i].uid] = aUser;
                this.users[this.competitorsArray[i].uid].trophy = i+1//this.pos
                this.users[this.competitorsArray[i].uid].cyferlink = environment.domain + "/cyferlink/" + aUser.uid;
                this.stackSubscribe = this.stack.getUserByIDString(this.competitorsArray[i].uid).subscribe(stack => {
                  if(stack){
                    this.users[this.competitorsArray[i].uid].stackProfileImage = stack.profile_image;
                  }
                })
                if(aUser.avatarPath){
                  this.users[this.competitorsArray[i].uid].downloadURL = aUser.avatarPath
                }
                if(aUser.avatarPath == null){
                  this.downloadURL = this.storage.ref("hackerIcon.png").getDownloadURL();
                  this.event2Subscribe = this.downloadURL.subscribe(url => {
                    if(url){
                      //console.log(url)
                      this.users[this.competitorsArray[i].uid].downloadURL = url
                    }
                  })
                }
                
                //console.log(aUser)
              }
            })
            //console.log(this.users)
          }
        }
        //console.log(this.competitorsArray)
        
  
      }
     
    })
  }

  chooseDate(type: string, event: MatDatepickerInputEvent<Date>) {
    
    if(type == "start"){
      this.startConverted = new Date(event.value).getTime();
      //console.log(this.startConverted)
    }
    if(type == "end"){
      this.endConverted = new Date(event.value).getTime();
      //console.log(this.endConverted)
    }
    this.onChange()
  }
  Today(){
    let date = new Date().setHours(0,0,0,0) 
    let firstDay = new Date(date)
    this.startConverted = new Date(firstDay).getTime();
    //this.startConverted = new Date(date.setDate(first));
    //console.log(this.startConverted)
    this.endConverted = new Date().getTime();
    this.onChange()
  }
  ThisWeek(){
    let date = new Date();
    let first = date.getDate() - date.getDay();
    let last = first +6;
    let firstday = new Date(date.setDate(first))
    //console.log(firstday)
    this.startConverted = new Date(firstday).getTime();
    //console.log(this.startConverted)
    this.endConverted = new Date().getTime();
    this.onChange()
  }
  ThisMonth(){
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1)
    this.startConverted = firstDate.getTime();
    //console.log(this.startConverted)
    this.endConverted = new Date().getTime();
    this.onChange()
  }
  LastMonth(){
    let date = new Date();
    let month = date.getMonth()-1;
    let year = date.getFullYear();
    if(date.getMonth()==0){
      year = date.getFullYear()-1
    }
    //console.log(month)
    this.startConverted = new Date(year, month, 1).getTime();
    //console.log(this.startConverted)
    this.endConverted = new Date(year, month+1, 0).getTime();
    this.onChange()
  }
    //const start = new Date(this.start).getTime();
    //const end = new Date(this.end).getTime();
    onChange(){
    //console.log("Start: "+this.startConverted+" End: "+this.endConverted)
    if(this.startConverted !=null && this.endConverted != null && this.startConverted < this.endConverted ){
      this.show = true;
      this.event3Subscribe = this.blastFurnaceService.getEventsInRange(this.startConverted, this.endConverted).subscribe(events => {
        this.competitors = {};

        for (const event of events) {
          //console.log(event)
          if (event.hasOwnProperty('users') && event.hasOwnProperty('results')){
            for (const user of event['users']) {
              //console.log(user)
              //console.log(event['results'])
              let key = 0
              for(let i= 0; i<event['results'].length; i++){
                //console.log(event['results'][i].uid)
                //console.log(user.uid)
                if(event['results'][i].uid != "participant"){
                  if(user.uid == event['results'][i].uid){
                    //console.log("found")
                    key = 1
                  }
                }
               
                
              }
              if(key == 1){
                if (!(user.uid in this.competitors)) {
                  //console.log(user.uid)
                  this.competitors[user.uid] = 0
                }
                this.competitors[user.uid] += 0
              }
              if(key ==0){
                if (!(user.uid in this.competitors)) {
                  //console.log(user.uid)
                  this.competitors[user.uid] = 1
                }
                else{
                  this.competitors[user.uid] = this.competitors[user.uid]+ 1
                }
                
              }
            }

            for (const result of event['results']) {
              //console.log(result.score)
              //console.log(result.uid)
              this.competitors[result.uid] += result.score;
            }
          }
        }

        this.competitorsArray = [];
        for (const competitor of Object.keys(this.competitors)) {
          if(competitor != "Participant"){
            this.competitorsArray.push({
              uid: competitor,
              score: this.competitors[competitor]
            })
          }
        }

        function sortBy(field) {
          return function(a, b){
            if (a[field] > b[field]) {
              return -1;
            } else if (a[field] < b[field]) {
              return 1;
            } else {
              return 0;
            }
          };
        }
        this.competitorsArray.sort(sortBy('score'));
        //console.log(this.competitorsArray)
        this.pos=1
        for(let j=0; j<this.competitorsArray.length; j++){
          this.user4Subscribe= this.auth.getUserByIDString(this.competitorsArray[j].uid).subscribe(aUser => {
            if(aUser){
              if(this.users[this.competitorsArray[j].firstName] != null){
                //console.log(this.users[this.competitorsArray[j].uid])
                this.users[this.competitorsArray[j].uid].trophy = this.pos
                this.pos = this.pos+1;
              }
              
            }
          })
        }
        //console.log(this.competitors)

        for (const user of Object.keys(this.competitors)){
          //console.log(user)
          if (!(user in this.users)) {
           this.user5Subscribe = this.auth.getUserByIDString(user).subscribe(aUser => {
              if(aUser){
                this.users[user] = aUser;
                //console.log(this.users)
              }
            })
          }
        }
       
        //console.log(this.users)
      });
  }


  }



  ngOnDestroy() {
    if(this.eventSubscribe){
      this.eventSubscribe.unsubscribe();
    }
    if(this.event2Subscribe){
      this.eventSubscribe.unsubscribe();
    }
    if(this.event3Subscribe){
      this.eventSubscribe.unsubscribe();
    }
    if(this.stackSubscribe){
      this.stackSubscribe.unsubscribe();
    }
    if(this.userSubscribe){
      this.userSubscribe.unsubscribe();
    }
    if(this.user4Subscribe){
      this.userSubscribe.unsubscribe();
    }
    if(this.user5Subscribe){
      this.userSubscribe.unsubscribe();
    }
    if(this.downloadURLSubscribe){
      this.downloadURLSubscribe.unsubscribe();
    }


  }
}
