import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { AdminGuard } from './core/admin.guard';
import {PageNotFoundComponent} from './ui/page-not-found/page-not-found.component'
import { BlastFurnaceAdminComponent } from './blast-furnace-admin/blast-furnace-admin.component';
import { BlastFurnaceLeaderboardComponent } from './blast-furnace-leaderboard/blast-furnace-leaderboard.component';
import {BlastFurnaceComponent} from './blast-furnace/blast-furnace.component';
import { BlastFurnaceCompHistoryComponent } from './blast-furnace/blast-furnace-comp-history/blast-furnace-comp-history.component';
import { BlastFurnaceEventComponent } from "./blast-furnace-event/blast-furnace-event.component";
import { BlastFurnaceLessonsComponent } from './blast-furnace-lessons/blast-furnace-lessons.component';
import {TermsComponent} from './ui/terms/terms.component'
import {LoginComponent} from './ui/login/login.component'


const routes: Routes = [
  //{ path: '', component: HomeComponent, },
  { path: '', component: BlastFurnaceComponent, },
/*   { path: 'history', component: BlastFurnaceCompHistoryComponent, }, */
  { path: 'results/:id', component: BlastFurnaceAdminComponent, canActivate: [AdminGuard] },
/*   { path: 'leaderboard', component: BlastFurnaceLeaderboardComponent, }, */
  { path: 'lessons', component: BlastFurnaceLessonsComponent, },
  { path: 'login', component: LoginComponent},
  { path: 'new', component: BlastFurnaceEventComponent, canActivate: [AdminGuard]},
  { path: 'terms/:id', component: TermsComponent, },
  {path: '404', component: PageNotFoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, AdminGuard]
})
export class AppRoutingModule { }
