import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
///// Start FireStarter

// Core
import { CoreModule } from './core/core.module';

// Feature Modules
import { UiModule } from './ui/shared/ui.module';
///// End FireStarter


// MDB Imports
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { ToastModule } from 'ng-uikit-pro-standard';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
// End MDB Imports

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
export const firebaseConfig = environment.firebaseConfig;
export const firebaseConfigSecondary = environment.firebaseConfigSecondary;
export const googleAPIKey = environment.googleAPIKey;
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlastFurnaceAdminComponent } from "./blast-furnace-admin/blast-furnace-admin.component";
import { BlastFurnaceLeaderboardComponent } from './blast-furnace-leaderboard/blast-furnace-leaderboard.component';
import { BlastFurnaceEventComponent } from './blast-furnace-event/blast-furnace-event.component';
import { BlastFurnaceCompHistoryComponent } from './blast-furnace/blast-furnace-comp-history/blast-furnace-comp-history.component';
import { BlastFurnaceLessonsComponent } from './blast-furnace-lessons/blast-furnace-lessons.component';

//CHARTS
import { BlastFurnaceComponent } from './blast-furnace/blast-furnace.component';

//Date Picker
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { TopNavComingSoonComponent } from './coming-soon/top-nav-coming-soon/top-nav-coming-soon.component';
import { FooterNavComingSoonComponent } from './coming-soon/footer-nav-coming-soon/footer-nav-coming-soon.component';
import { HomeComingSoonComponent } from './coming-soon/home-coming-soon/home-coming-soon.component';



@NgModule({
  declarations: [
    AppComponent,
    BlastFurnaceAdminComponent,
    BlastFurnaceLeaderboardComponent,
    BlastFurnaceEventComponent,
    BlastFurnaceComponent,
    TopNavComingSoonComponent,
    FooterNavComingSoonComponent,
    HomeComingSoonComponent,

    BlastFurnaceCompHistoryComponent,

    BlastFurnaceLessonsComponent,


  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    UiModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({ apiKey: googleAPIKey, libraries: ["places"] }),
    ToastModule.forRoot(),
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [MDBSpinningPreloader],
  schemas: [ NO_ERRORS_SCHEMA ]
})

export class AppModule { }