import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../../core/navbar.service';
import { Subscription } from 'rxjs';
import { AngularFirestore} from '@angular/fire/firestore';
import { User } from '../../core/user';
import { environment } from '../../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'blast-furnace-comp-history',
  templateUrl: './blast-furnace-comp-history.component.html',
  styleUrls: ['./blast-furnace-comp-history.component.scss']
})
export class BlastFurnaceCompHistoryComponent implements OnInit {

  EventSub: Subscription
  UserSub: Subscription
  Events = []
  resultsLength:number
  constructor(public nav: NavbarService, private afs: AngularFirestore,private title:Title, private meta:Meta,) { }

  ngOnInit() {
    this.nav.hide();
    this.title.setTitle('Blast Furnace')
    this.meta.addTags([
      { property: 'og:site_name', conent: environment.metadata.name},
      { property: 'og:url', content: environment.metadata.url + ''},
      { property: 'og:title', content: 'Blast Furnace Competition Event History'},
      { property: 'og:description', content: 'The Blast Furnace Competition Event History shows the results of all past competitions.'},
      { property: 'og:image', content: environment.metadata.image},
      { property: 'og:type', content: 'website'},
      { property: 'og:video', content: environment.metadata.video},
      { name: 'description', content: 'The Blast Furnace Competition Event History shows the results of all past competitions.'},
      { name: 'twitter:card', content: 'summary'},
      { name: 'twitter:site', content: environment.metadata.site},
      { name: 'twitter:title', content: 'Blast Furnace Competition Event History'},
      { name: 'twitter:description', content: 'The Blast Furnace Competition Event History shows the results of all past competitions.'},
      { name: 'twitter:image', content: environment.metadata.image}
    ])
    this.EventSub =  this.afs.collection(`blastFurnaceEvent`, ref => ref.orderBy('timestamp', 'desc').limit(100)).valueChanges().subscribe(events =>{
      if(events){
        this.Events=events;
        console.log(this.Events);
        console.log(this.Events.length);
        
        for(let i=0; i<this.Events.length; i++){
          if(this.Events[i].results){
            if(this.Events[i].results.length<3){
              this.resultsLength=this.Events[i].results.length
            }
            else{
              this.resultsLength=3
            }
            //console.log(this.Events[i].results.length)
            for(let k=0; k<this.resultsLength; k++){
              //console.log(this.Events[i].results[k].uid)
              const path = `users/${this.Events[i].results[k].uid}`;
              this.UserSub = this.afs.doc<User>(path).valueChanges().subscribe(user => {
                if(user){
                  this.Events[i].results[k].firstName = user.firstName;
                  this.Events[i].results[k].lastName = user.lastName;
                  this.Events[i].results[k].avatar = user.selectedAvatarURL
                }
                else{
                  //console.log("no user found")
                  //this.resultsLength = this.resultsLength-1
                  for(let t= k; t<this.resultsLength; t++){
                    this.Events[i].results[t] = this.Events[i].results[t+1]
                  }
                  if(this.Events[i].results[this.resultsLength]){
                    if(this.Events[i].results[this.resultsLength].firstName ==null){
                      this.Events[i].results[this.resultsLength].score = null
                    }
                  }
                  k = k-1
                }
              });
              //console.log(this.Events[i].results[k])
              let timestamp = new Date(this.Events[i].timestamp)
              //console.log(timestamp.toDateString())
              this.Events[i].date = timestamp.toDateString()
              //console.log(timestamp.toLocaleTimeString());
              this.Events[i].time = timestamp.toLocaleTimeString()
              
              let userUID = this.Events[i].results[k].uid
              for(let j=0; j<this.Events[i].users.length; j++){
                if(this.Events[i].results[k].uid == this.Events[i].users[j].uid){
                  this.Events[i].results[k].language = this.Events[i].users[j].language
                }
              }
            
            }
          }
          
        }
        //console.log(this.Events)
      }
    });
  }

}
