import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Observable , BehaviorSubject,  Subscription } from 'rxjs';
import { ToastService } from 'ng-uikit-pro-standard'; 
import { User, SharedUser } from './user';
import { BlastFurnaceService } from './blastfurnace.service';
import { storage } from 'firebase';
import { createStorageRef } from '@angular/fire/storage'; 


@Injectable()
export class AdminService {

  constructor(private afAuth: AngularFireAuth,
    private router: Router,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private toast: ToastService,
    private blastfurnace: BlastFurnaceService,
    ) { }


    setUpdateEmail (emailBody: any){
      const doc = `admin/updateEmail`;
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(doc);
      userRef.set(emailBody).catch(error => this.handleError(error) );
    }

    private handleError(error) {
      this.toast.error(error.message)
    }

}
