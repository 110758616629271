<div class="blast-furnace">
<div class="full-bg flex"  style="background-image: url(../../assets/images/blastfurnace.jpg); background-size: cover; ">
    
    <blast-furnace-nav [enableLogo]="true"></blast-furnace-nav>   

</div>
<div class="intro-info-content text-center">
  <h6 class="heading" data-wow-delay="0.3s">LEADERBOARD</h6>
</div>

<div class="containerLeaderboard">
  <div class="row justify-content-end">
  <div class="col col-md-8">
    <div class="container">
        
      <div class="card" >
        
        <div class="card-body" style="width:100%; padding-left:0%; padding-right:0%; padding-top:0%; padding-bottom:3%" >
                    <mdb-navbar SideClass="navbar navbar-expand-xl navbar-light  lighten-5" >
                        <logo>
                            <h6 class=" filterHeading" style="white-space: nowrap">FILTER</h6>
                        </logo>
                        <links>
                            <ul class="navbar-nav mr-auto">
                                    <li class="col-12 col-sm-auto" ripple-radius> 
                                        <a class="filterbutton" style="white-space: nowrap" (click)="Today()" mdbWavesEffect>Today</a>
                                    </li>
                                    <li class="col-12 col-sm-auto" ripple-radius>
                                        <a class="filterbutton" style="white-space: nowrap" (click)="ThisWeek()" mdbWavesEffect>This Week</a>
                                    </li>
                                    <li class="col-12 col-sm-auto" ripple-radius>
                                        <a class="filterbutton" style="white-space: nowrap" (click)="ThisMonth()" mdbWavesEffect>This Month</a>
                                    </li>
                                    <li class="col-12 col-sm-auto" ripple-radius>
                                        <a class="filterbutton" style="white-space: nowrap" (click)="LastMonth()" mdbWavesEffect>Last Month</a>
                                    </li>
                                    <li class="col-12 col-sm-auto" ripple-radius>
                                        <a class="filterbutton" style="white-space: nowrap" (click)="onChange2()" mdbWavesEffect>All Time</a>
                                    </li>
                                </ul> 
                                <ul class="navbar-nav nav-flex-icons">
                                    <li class="nav-item waves-light" mdbWavesEffect>
                                        <mat-form-field>
                                            <input matInput [matDatepicker]="start" placeholder="Starting Date"  (dateInput)="chooseDate('start', $event)">
                                            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi="true" #start></mat-datepicker>
                                        </mat-form-field>
                                    </li>
                                    <li class="nav-item waves-light" mdbWavesEffect>
                                        <mat-form-field>
                                            <input matInput [matDatepicker]="end" placeholder="Ending Date"  (dateInput)="chooseDate('end', $event)">
                                            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi="true" #end></mat-datepicker>
                                        </mat-form-field>
                                    </li>
                                </ul>
                        </links>
                    </mdb-navbar>
        <table class="table" >
            <thead class="tableHeader"> 
                <tr>
                    <th class="tableHeadings" style="text-align: left">NAME</th>
                    <th class="tableHeadings"style="text-align: right">SCORE</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let competitor of competitorsArray; let i = index">
                    <td *ngIf="competitor.score > 0  && users[competitor.uid] && users[competitor.uid]['preferredAvatar']=='upload'">
                        <div *ngIf="users[competitor.uid]['downloadURL']">
                            <img src="{{users[competitor.uid]['downloadURL']}}">  <a href="{{users[competitor.uid]['cyferlink']}}">{{users[competitor.uid]['firstName']}} {{users[competitor.uid]['lastName']}}</a>
                        </div>
                    </td>
                    <td *ngIf="competitor.score > 0  && users[competitor.uid] && users[competitor.uid]['preferredAvatar']=='github'">
                        <img src="{{users[competitor.uid]['photoURL']}}"> <a href="{{users[competitor.uid]['cyferlink']}}">{{users[competitor.uid]['firstName']}} {{users[competitor.uid]['lastName']}}</a>
                    <td *ngIf="competitor.score > 0  && users[competitor.uid] && users[competitor.uid]['preferredAvatar']=='stack'">
                        <img src="{{users[competitor.uid]['stackProfileImage']}}"> <a href="{{users[competitor.uid]['cyferlink']}}">{{users[competitor.uid]['firstName']}} {{users[competitor.uid]['lastName']}}</a>
                    </td>
                    <td *ngIf="competitor.score > 0  && users[competitor.uid]" style="text-align: right">
                        <div *ngIf="users[competitor.uid]['trophy']">
                            <div *ngIf="i==0">
                                <i  class="fa fa-trophy trophyGold"></i> {{competitor.score}}
                            </div>
                            <div *ngIf="i==1">
                                <i class="fa fa-trophy trophySilver"></i> {{competitor.score}}
                            </div>
                            <div *ngIf="i==2">
                                <i class="fa fa-trophy trophyBronze"></i> {{competitor.score}}
                            </div>
                            <div *ngIf="i>2">
                                {{competitor.score}}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
      </div>
    </div>
    </div>
    <div class="col-12 col-md-2" >
        <h6 class="InvolvedDesc" style="text-align: center; padding-top:3%">SPONSORED BY</h6>
        <div *ngIf="bfData">
            <div *ngFor="let bfimage of bfData.supporterImageUrl; let k = index">
                <center><img class="image1" [src]="bfData.supporterImageUrl[k]"></center>
            </div>
        </div>
    </div>
</div>



<div class="intro-info-content text-center">
    <img class="image" src="../../../assets/images/image.png">
</div>

<register></register>
</div>
