import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Lesson, Resource } from '../core/user';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'blast-furnace-lessons',
  templateUrl: './blast-furnace-lessons.component.html',
  styleUrls: ['./blast-furnace-lessons.component.scss']
})
export class BlastFurnaceLessonsComponent implements OnInit {
  blastfurnace:Subscription;
  bfData:any;
  //lessonsArray: Lesson[] = [];
  lessonsArray: Lesson[];
  lesson: Lesson;
  video

  constructor(private afs: AngularFirestore, public sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    const path = `admin/blastFurnace`; 
    this.blastfurnace = this.afs.doc(path).valueChanges().subscribe(data =>{
      if (data){
        this.bfData = data;
        this.lessonsArray = this.bfData.lessonsArray.slice().reverse();
        for (var i = 0; i < this.lessonsArray.length;i++){
          this.lessonsArray[i].videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.lessonsArray[i].videoLink)
        }
        //  console.log(this.lessonsArray);
      }
    });
    
  }

  containsYoutube(link:any): boolean{
    console.log(link);
    if(link.changingThisBreaksApplicationSecurity.includes("youtube")){
        return true;
    } else {return false;}
  }

}
