
import {tap, map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService} from './auth.service';
import { ToastService } from 'ng-uikit-pro-standard'; 



import * as _ from 'lodash';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection} from '@angular/fire/firestore';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private toast: ToastService, private afs: AngularFirestore) {}
  userSub: Subscription
  roleSub: Subscription
  user: any
  messageUsed: boolean
  loop = false

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

      return this.auth.user.pipe(
           take(1),
           map(user => !!user),
           tap(loggedIn => {
            if (!loggedIn) {
              //console.log('access denied')
              this.toast.info('You must be logged in to access this page!')
              this.router.navigate(['/login']);
            }
            else{
              this.messageUsed = false
              this.userSub =  this.auth.currentUser.subscribe((user) => {
                if(user){
                  this.user = user
                  if(this.messageUsed == false){
                    if(this.user.termsAccepted == null || this.user.termsAccepted == false){
                      this.messageUsed = true
                      //this.toast.info('You must accepted the Terms of Use!', 'Info')
                      this.router.navigate(['/terms/accept']);
                    }
                    else{
                      if(this.loop ==false){
                        this.roleSub = this.auth.roles.subscribe(roles => {
                          if (roles) {
                            this.messageUsed = true
                            this.loop = true
                            //console.log(roles)
                            //console.log(this.auth.isHacker)
                            if(roles['hacker'] === true){
                              this.router.navigate(['hacker']);
                            }
                            else{
                              this.router.navigate(['recruiter']);
                            }
                          }
                        }) 
                      }
                    }
                  }  
                }       
              })
            }
         }),)

  }
}


