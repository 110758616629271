<blast-furnace-nav [enableLogo]="true"></blast-furnace-nav> 
<!--<div class="container" style="padding-top: 1%">
  <div class="card z-depth-0" >
    <div class="card-body">-->
<div class="container" style="padding-top: 1%">
        <div class="card tabs">
                <div class="container">
                    <div class="card-body">
                        <!-- <div class="row container flex-center" style="padding-bottom: 1%">
                            <div class="col">
                                <button class="btn hackerlinxBlueBG z-depth-1" style="width: 100%"  routerLink="/terms/use" >Terms of Use</button>
                            </div>
                            <div class="col">
                                <button class="btn hackerlinxBlueBG z-depth-1" style="width: 100%"  routerLink="/terms/privacy" >Privacy Policy</button>
                            </div>
                        </div> -->
                        <div class="row container flex-center tab-headers"> 
                            <a class="col-sm-6 " (click)="select('use')" [ngClass]="policy=='use' ? 'searchTabsSelected' : 'searchTabs'">
                                <a ><i class="fa fa-info fa-2x hackerlinxGreen" ></i> Terms of Use</a>     
                            </a>
                            <a class="col-sm-6" (click)="select('privacy')" [ngClass]="policy=='privacy' ? 'searchTabsSelected' : 'searchTabs'">
                                <a ><i class="fa fa-user-secret fa-2x hackerlinxGreen" ></i> Privacy Policy</a>
                            </a>
                        </div>
                        <terms-of-use *ngIf="policy == 'use' || policy == 'accept'"></terms-of-use>
                        <privacy *ngIf="policy == 'privacy'"></privacy>
                    
                    </div>
                </div>
              
            
        </div>
</div>
