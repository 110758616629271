import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlastFurnaceService } from '../core/blastfurnace.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'blast-furnace-admin',
  templateUrl: './blast-furnace-admin.component.html',
  styleUrls: ['./blast-furnace-admin.component.scss']
})

export class BlastFurnaceAdminComponent implements OnInit, OnDestroy{
  placesTemplate = [{
      nameQuery: '',
      name: 'First',
      user: null,
      users: [],
      score: 0,
    },
    {
      nameQuery: '',
      name: 'Second',
      user: null,
      users: [],
      score: 0,
    },
    {
      nameQuery: '',
      name: 'Third',
      lang: '',
      user: null,
      users: [],
      score: 0,
    },
    {
      nameQuery: '',
      name: 'Participant',
      lang: '',
      user: null,
      users: [],
      score: 0,
    }];

  places = [];
  score = [];

  totalCompetitors = 0;
  pointsPerCompetitor = 0;

  users = {};
  event: any;
  userLanguage: any;
  userLanguageSubscription : Subscription
  languageLeaderboard: any;
  languageLeaderboardTotal: any;
  languageLeaderboardSubscription: Subscription;
  languageLeaderboardTotalSubscription: Subscription;
  languageLeaderboardTotal2Subscription: Subscription;
  userAdd:any;
  eventSub = null;
  eventLan = null;
  eventID: string;
  routeSub: Subscription;
  authSub: Subscription;
  addEventSub: Subscription;
  updateUserLangSub: Subscription;
  updateLeaderboardLanguageSub: Subscription;
  updateLeaderboardTotalSub: Subscription;
  Tally: number
  Tally2: number
  multiplier =1



  search(searchString) {
    return Object.keys(this.users).filter(user => this.users[user].displayName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
  }


  recalculateScores() {
    console.log(this.places)
    const avail = this.totalCompetitors * this.pointsPerCompetitor;
    this.places[0].score = this.multiplier * Math.round(avail * 10 / 17);
    this.places[1].score = Math.round(avail * 5 / 17);
    this.places[2].score = Math.round(avail * 2 / 17);
    this.places[3].score = Math.round(1);
  }

  initPlaces() {
    this.places = Object.assign([], this.placesTemplate)
  }

  constructor(private blastFurnaceService: BlastFurnaceService, private route: ActivatedRoute, private auth: AuthService, private router: Router) {
    this.initPlaces();
    this.routeSub = this.route.params.subscribe(params => {
      if(params){
        this.eventID = params['id'];
      this.eventSub = this.blastFurnaceService.getEvent(params['id']).subscribe(event => {
        this.event = event;
        console.log(this.event)
        this.totalCompetitors = this.event.users.length;
        for (const user of this.event.users) {
          this.authSub = this.auth.getUserByIDString(user.uid).subscribe(aUser => {
            this.users[user.uid] = aUser;
          })
        }
      })
      }
    });
  }

  save() {
    const places = [];
    
    for (const place of this.places.slice(0, this.totalCompetitors)){
      console.log(place)
      if(place.name != "Participant"){
        places.push({
          uid: place.user.uid,
          score: place.score,
        })
      } 
    }
    places.push({
      uid: "Participant",
      score: 1,
    })

    

    for(let i=0; i<this.event.users.length; i++){
      let update = false;
      let updateLeaderboard = false;
      console.log("number of users at event", this.event.users.length)
      console.log("uid: "+this.event.users[i].uid+" language: "+this.event.users[i].language)
      console.log("number or times: "+i)
      let runLeaderboard = false
      this.languageLeaderboardSubscription = this.blastFurnaceService.getLeaderboard(this.event.users[i].uid, this.event.users[i].language).subscribe(leaderboard => {
        if(runLeaderboard == false){
          if(leaderboard){
          this.languageLeaderboard = leaderboard
          console.log(this.languageLeaderboard)
          let position
          let PolPosition = false
          for(let l=0; l<places.length; l++){
            if(places[l].uid == this.event.users[i].uid){
              position = l
              PolPosition=true
            }
          }
          if(PolPosition==false){
            position=3
            //places[position].score = 1
          }
          console.log(position)
          console.log("Tally: "+places[position].score)
          
          if(this.languageLeaderboard == ""){
            let totalupdate = false;
            this.blastFurnaceService.createLeaderboardUser(this.event.users[i].uid, this.event.users[i].language, places[position].score)
            this.languageLeaderboardTotalSubscription = this.blastFurnaceService.getLeaderboard(this.event.users[i].uid, "Total").subscribe(leaderboardTotal => {
              if(leaderboardTotal){
                this.languageLeaderboardTotal= leaderboardTotal
                if(this.languageLeaderboardTotal == ""){
                  updateLeaderboard = true;
                  this.blastFurnaceService.createLeaderboardTotal(this.event.users[i].uid, places[position].score)
                }
                if(this.languageLeaderboardTotal && updateLeaderboard == false){
                  console.log(this.languageLeaderboardTotal[0])
                  let total = this.languageLeaderboardTotal[0].score + places[position].score
                  if(totalupdate == false){
                    //let score = this.languageLeaderboard[0].score + places[position].score
                    updateLeaderboard = true;
                    totalupdate = true;
                    //this.blastFurnaceService.updateLeaderboardUser(this.event.users[i].uid, this.event.users[i].language, score)
                    this.blastFurnaceService.updateLeaderboardTotal(this.event.users[i].uid, total)
                  }
                }
              }
            })
            
            
          }
          if(this.languageLeaderboard && updateLeaderboard ==false){
            let totalupdate = false
            let score = 0
            console.log(this.languageLeaderboard)

            /*this.blastFurnaceService.getUserRank2(this.event.users[i].language, this.event.users[i].uid).subscribe(scores => {
              if(scores){
                let temp = scores[0].score
                console.log(temp)
                if(this.languageLeaderboard != ""){
                  score = this.languageLeaderboard[0].score + temp
                }
                if(this.languageLeaderboard == ""){
                  score = temp
                }
              }
            })*/
            if(this.languageLeaderboard != ""){
              score = this.languageLeaderboard[0].score + places[position].score
            }
            if(this.languageLeaderboard == ""){
              score = places[position].score
            }
            console.log("updating1")
            let functionComplete = false
            this.languageLeaderboardTotal2Subscription = this.blastFurnaceService.getLeaderboard(this.event.users[i].uid, "Total").subscribe(leaderboardTotal => {
              
              if(functionComplete == false){
                console.log("updating2", leaderboardTotal)
                if(leaderboardTotal){
                  let total = 0
                  this.languageLeaderboardTotal= leaderboardTotal
                  if(this.languageLeaderboardTotal != ""){
                    total = this.languageLeaderboardTotal[0].score + places[position].score
                  }
                  if(this.languageLeaderboardTotal == ""){
                    total = places[position].score
                  }
                  console.log(totalupdate)
                  if(totalupdate == false){
    
                    updateLeaderboard = true;
                    totalupdate = true;
                    console.log("updating3", score)
                    this.updateLeaderboardLanguageSub = this.blastFurnaceService.updateLeaderboardUser(this.event.users[i].uid, this.event.users[i].language, score).subscribe((success) =>{
                      if(success){
                        console.log("user Language leaderboard updated")
                      }
                    })
                    this.updateLeaderboardTotalSub = this.blastFurnaceService.updateLeaderboardTotal(this.event.users[i].uid, total).subscribe((success) =>{
                      if(success){
                        console.log("user Total leaderboard updated")
                      }
                    })
                  
                  }
                }
                functionComplete = true
              }
            })
          }
          }
          runLeaderboard = true;
        }
      })
      console.log("update user language " + this.event.users[i].uid)
      this.userLanguageSubscription = this.blastFurnaceService.getUser(this.event.users[i].uid).subscribe(language => {
        if(language){
          this.userLanguage = language;
          console.log(language)
          if(this.userLanguage == null){
            console.log("adding user Language")
            this.blastFurnaceService.addUserLanguage(this.event.users[i].uid,this.event.users[i].language)
          }
          if(this.userLanguage && update ==false){
            console.log("update user language 1", language)
              let selectedLanguage = this.event.users[i].language
              let position2
              let PolPosition2 =false
              for(let l=0; l<places.length; l++){
                if(places[l].uid == this.event.users[i].uid){
                  position2 = l
                  PolPosition2 = true
                }
              }
              if(PolPosition2==false){
                position2=3
                //places[position2].score = 1
              }
             
              console.log(position2)
              console.log("Tally 2: "+places[position2].score)

              this.userLanguage[selectedLanguage.toLowerCase()] += places[position2].score;
              this.userLanguage['total'] += places[position2].score;

              update = true;
              this.updateUserLangSub = this.blastFurnaceService.updateUserLanguage(this.event.users[i].uid,this.userLanguage).subscribe((success) => {
                if (success) {
                
                  console.log("Successfully Updated")
                  this.router.navigate(['/blastfurnace/leaderboard'])
                }
              });
          }
        }
        if(language == null){
          console.log("adding user Language")
          this.blastFurnaceService.addUserLanguage(this.event.users[i].uid,this.event.users[i].language).subscribe((success) =>{
            if(success){
              this.router.navigate(['/blastfurnace/leaderboard'])
            }
          })
        }
      })
    
    }
    this.initPlaces();

    this.addEventSub = this.blastFurnaceService.addEventResults(this.eventID, places).subscribe((success) => {
      if (success) {
        //this.router.navigate(['/blastfurnace/leaderboard'])
      }
    });
  
  }

  ngOnInit() {
  }
  ngOnDestroy(){
    if(this.routeSub){
      this.routeSub.unsubscribe();
    }
    if(this.eventSub){
      this.eventSub.unsubscribe();
    }
    if(this.authSub){
      this.authSub.unsubscribe();
    }
    if(this.addEventSub){
      this.addEventSub.unsubscribe();
    }
    if(this.languageLeaderboardSubscription){
      this.languageLeaderboardSubscription.unsubscribe();
    }
    if(this.languageLeaderboardTotalSubscription){
      this.languageLeaderboardTotalSubscription.unsubscribe();
    }
    if(this.languageLeaderboardTotal2Subscription){
      this.languageLeaderboardTotal2Subscription.unsubscribe();
    }
    if(this.userLanguageSubscription){
      this.userLanguageSubscription.unsubscribe();
    }
    if(this.updateUserLangSub){
      this.updateUserLangSub.unsubscribe();
    }
    if(this.updateLeaderboardTotalSub){
      this.updateLeaderboardTotalSub.unsubscribe();
    }
    if(this.updateLeaderboardLanguageSub){
      this.updateLeaderboardLanguageSub.unsubscribe();
    }


  }

}
