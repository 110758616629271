<blast-furnace-nav [enableLogo]="true"></blast-furnace-nav>   
<body>

    <div class="container">
            <div class="row justify-content-md-center align-items-center" style="padding-top: 4em">
                <div class="card col-12 col-md-8 col-lg-6" style="padding: 0;">
                    <h3 class="card-header mdb-color darken-3 white-text text-center">
                        <img class="Logo" routerLink="/" onmouseover="" style="cursor: pointer;"
                            src="../../../assets/images/LogoMain.svg">
                    </h3>
                    <div class="card-body">
                        <h5 class="card-title text-center" style="margin: 2.5em 0">Log in to CYFERLINX</h5>
        
                        <p *ngIf="error" class="text-center red-text">{{ error.message }}</p>
        
                        <form #formData='ngForm' (ngSubmit)="onSubmit(formData)">
                            <div class="md-form">
                                <i class="hackerlinxGreen fa fa-envelope prefix grey-text"></i>
                                <input type="text" id="defaultForm-email" class="form-control" [(ngModel)]="email" name="email"
                                    mdbInputDirective [validateSuccess]="false" [validateError]="false" required>
                                <label for="defaultForm-email">Your email</label>
                            </div>
        
                            <div class="md-form">
                                <i class="hackerlinxGreen fa fa-lock prefix grey-text"></i>
                                <input type="password" id="defaultForm-pass" class="form-control" [(ngModel)]="password"
                                    name="password" mdbInputDirective [validateSuccess]="false" [validateError]="false"
                                    required>
                                <label for="defaultForm-pass">Your password</label>
                            </div>
        
        
                            <div class="text-center">
                                <p class="small"><a routerLink="/reset">Forgot your password?</a></p>
                                <button type="submit" class="btn hackerlinxGreenBG" [disabled]="!formData.valid"
                                    ripple-radius>Login</button>
                            </div>
                        </form>
        
                    </div>
                </div>
            </div>
        </div>
        <!--/.Panel-->
        <div class="text-center" style="padding: 2em;">
            <p>Not a member? <a routerLink="/signup" routerLinkActive="active" class="alc">Sign Up</a></p>
        </div>
        <app-footer></app-footer>
</body>
