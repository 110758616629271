import { Injectable } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, from as fromPromise, BehaviorSubject, Subscription, of } from 'rxjs';
import { StackUser } from './user';


@Injectable()
export class StackService {
  readonly ROOT_URL = 'https://api.stackexchange.com';
  posts: Observable<any>;
  StackData: any;
  userObs: Observable<StackUser> = of(null);
  currentUser: BehaviorSubject<StackUser> = new BehaviorSubject(null);
  stackSub: Subscription;
  private userLoaded: boolean = false;
  ;

  constructor(private activatedRoute: ActivatedRoute,
    private afs: AngularFirestore,
) {

  }


  getUserByIDString(userIDString): BehaviorSubject<StackUser> {
    const path = `stackoverflow/${userIDString}`; // Endpoint on firebase
    const userRef: AngularFirestoreDocument<any> = this.afs.doc<StackUser>(path);
    const sharedUser: BehaviorSubject<StackUser> = new BehaviorSubject(null);
    this.afs.doc<StackUser>(path).valueChanges().subscribe(sharedUser);
    return sharedUser;
  }

}

