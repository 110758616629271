import { Component, OnInit } from '@angular/core';
import { BlastFurnaceService } from '../core/blastfurnace.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../core/user';

@Component({
  selector: 'blast-furnace-event',
  templateUrl: './blast-furnace-event.component.html',
  styleUrls: ['./blast-furnace-event.component.scss']
})
export class BlastFurnaceEventComponent implements OnInit {
  competitors: Array<object> = [];
  createSubscribe: Subscription;
  userSubscribe: Subscription;
  EventSub: Subscription;
  UserSub: Subscription;
  languages = [
    'Bash',
    'C',
    'C++',
    'C#',
    'Clojure',
    'Dart',
    'F#',
    'Java',
    'JavaScript',
    'Go',
    'Groovy',
    'Haskell',
    'Kotlin',
    'Lua',
    'Objective-C',
    'OCaml',
    'Pascal',
    'Perl',
    'PHP',
    'Python2',
    'Python3',
    'Ruby',
    'Rust',
    'Scala',
    'Swift',
    'VB.NET'
  ];
  Events = [];
  previous = false;

  constructor(private blastFurnaceService: BlastFurnaceService, private router: Router,  private afs: AngularFirestore, ) {
  }

  setLanguage(competitor) {
    competitor.language = this.languages.filter(lang => lang.toLowerCase().indexOf(competitor.languageInput.toLowerCase()) !== -1)[0]
  }

  delete(competitor) {
    if (this.competitors.indexOf(competitor) > -1) {
      this.competitors.splice(this.competitors.indexOf(competitor), 1);
    }
  }

  setUserByEmail(competitor) {
    if (competitor.email.length > 2){
      this.userSubscribe = this.blastFurnaceService.getUserByEmail(competitor.email).subscribe(users => {
        if (users.length > 0) {
          competitor.user = users[0]
        } else {
          competitor.user = null;
        }
      });
    } else {
      competitor.user = null;
    }
  }

  addCompetitor() {
    this.competitors.push({
      email: '',
      user: null,
      languageInput: '',
      language: ''
    })
  }

  previousCompetitors(){
    this.previous = true;
    this.EventSub =  this.afs.collection(`blastFurnaceEvent`, ref => ref.orderBy('timestamp', 'desc').limit(1)).valueChanges().subscribe(events =>{
      if(events){
        this.Events=events[0]['users']
        this.Events.forEach(user=>{
          this.UserSub = this.afs.doc<User>(`users/${user.uid}`).valueChanges().subscribe(userdata =>{
            if(userdata){
              this.competitors.push({
                email: userdata.email,
                user: userdata,
                languageInput: '',
                language: ''
              })
            }
          })
        })
      }
    })
  }

  save() {
    const validCompetitors = [];
    const userCache = {};

    for (const competitor of this.competitors) {
      if (competitor['user'].uid !== null && !(competitor['user'].uid in userCache)) {
        validCompetitors.push({
          uid: competitor['user'].uid,
          language: competitor['language']
        });
        userCache[competitor['user'].uid] = true
      }
    }
    //console.log(validCompetitors)
    let timestamp = new Date().getTime();
    this.createSubscribe = this.blastFurnaceService.createEvent(validCompetitors, timestamp).subscribe(eventID => {
      //if (eventID !== ""){
      if (eventID){
        this.router.navigate([`/blastfurnace/results/${timestamp}`]);
        //this.router.navigate([`/blastfurnace/results/${eventID}`]);
      }
    });
    this.competitors = [];
  }

  ngOnInit() {
    
  }
  ngOnDestroy(){
    if(this.userSubscribe){
      this.userSubscribe.unsubscribe()
    }
    if(this.createSubscribe){
      this.createSubscribe.unsubscribe()
    }
  }

}
