// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    firebaseConfig: {
      apiKey: "AIzaSyDRjNCq-DzXzc22SBCuufC47UmSiwLhVhE",
      authDomain: "hackerlinxdev-a70df.firebaseapp.com",
      databaseURL: "https://hackerlinxdev-a70df.firebaseio.com",
      projectId: "hackerlinxdev-a70df",
      storageBucket: "hackerlinxdev-a70df.appspot.com",
      messagingSenderId: "83511232378",
      appId: "1:83511232378:web:32f52a4a9864e168"
    },
    firebaseConfigSecondary: {
      apiKey: "AIzaSyB2L6PeSYtSQVOOHb26v89myIcRqDRM6OY",
      authDomain: "blast-furnace-dev.firebaseapp.com",
      databaseURL: "https://blast-furnace-dev.firebaseio.com",
      projectId: "blast-furnace-dev",
      storageBucket: "blast-furnace-dev.appspot.com",
      messagingSenderId: "561819134748",
      appId: "1:561819134748:web:a060fdf8ebd2d1a121a8d9",
      measurementId: "G-K4EXSTHHGE"
    },
    developmentEnv: "Development",
    googleAPIKey: "AIzaSyDsSo1aQWDo5qSnPlRDYkKHV04fU2swgCI",
    metadata: {
      name: 'Blast Furnace',
      url: "https://blastfurnace.com.au",
      image: 'https://firebasestorage.googleapis.com/v0/b/hackerlinx-460dd.appspot.com/o/logo-white-trans.png?alt=media&token=208fad17-4b2a-49c6-93eb-caa2f8f2c448',
      site: '@_BlastFurnace',
      video: 'https://www.youtube.com/watch?v=mQGJyxIDNnc'
    },
    domain: "https://dev.cyferlinx.com"
  };
  