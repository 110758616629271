import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { User } from '../../core/user';
import { Subscriber, Subject, Subscription } from 'rxjs';
import { NavbarService } from '../../core/navbar.service';
import { TabsetComponent } from 'ng-uikit-pro-standard';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, OnDestroy {

  policy: string;
  private sub: Subscription;

  constructor(private nav: NavbarService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {
    this.nav.show();
    this.sub = this.route.params.subscribe(params => {
      this.policy = params['id'];
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe;
    }
  }

  select(tab) {
    this.policy = tab;
  }


}
